<template>
  <div class="title-contain">
    <!-- <members-project
      :idsite="idsite"
      :show-open-members-project.sync="showOpenMembersProject"
    /> -->
    <sidebar-ownership :idsite="siteObj.length >= 1 ? siteObj[0] : ''" :show-open.sync="showOpen" :type-ownership.sync="isNotAlreadyOwner" />
    <sidebar-pro-pages :idsite="siteObj.length >= 1 ? siteObj[0] : ''" :show-open-pro-pages.sync="showOpenSidebarProPages" :data-propages-sidebar="listPagesSidebars" />
    <register-sidebar :show-open-register-sidebar.sync="showOpenRegisterSidebar" />
    <add-site-project :show-open-add-site-project.sync="showOpenAddSiteProject" :idsite="siteObj.length >= 1 ? siteObj[0] : ''" />
    <info-subscription
      :idsite="siteObj.length >= 1 ? siteObj[0] : ''"
      :show-open-subscription.sync="showOpenSubscription"
    />
    <archive-site
      :idsite="siteObj.length >= 1 ? siteObj[0] : ''"
      :show-open-archive-site.sync="showOpenArchiveSite"
    />
    <span
      v-if="$route.name === 'view-errors' || $route.name === 'project-sites' || $route.name === 'edit-site' || $route.name === 'shared-site' || $route.name === 'projects'"
      style="display: none !important;"
    >
      {{ titleAtomic = $route.name }}
      <!-- {{ titleAtomic = $route.params.name }} -->
      <!-- {{ this.chargeSite }} && idsite.label !== 'My sites' -->
    </span>

    <div v-if="($route.name === 'view-errors')" :style="$route.name === 'shared-site' && $route.params.url ? 'position: absolute; top: 60px; left: 230px; z-index: 10;' : $route.name === 'view-errors' && $route.params.url ? 'position: absolute; top: 50px; left: 230px; z-index: 10;' : 'position: absolute; top: 50px; left: 230px; z-index: 10;'" class="project-name-header cursor-pointer" @click.prevent="$route.name === 'shared-site' ? showOpenAddSiteProject = true : logIdMembersProject(idsite.label ? idsite.label : title ? title : '', owner)">
      <h4
        class="color-text-white d-flex ml-1 cursor-pointer"
        :style="($route.params.own === 'other' && $route.name === 'project-sites') || (idsite && idsite.idsite && idsite.label && owner === 'other' && $route.name === 'project-sites') ? 'line-height: 1.1; font-weight: 400 !important; margin-bottom: 14px;' : 'line-height: 1.1; font-weight: 400 !important;'"
        @click.prevent="$route.name === 'shared-site' ? showOpenAddSiteProject = true : logIdMembersProject(idsite.label ? idsite.label : title ? title : '', owner)"
        v-if="userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' && $route.name === 'shared-site'"
      >
        <div :style="`min-width: 12px; width: 12px; height: 12px; border-radius: 4px; background: ${ idsite.color ? idsite.color : colorProject }; margin-top: 3px;`" class="mr-50"></div>
        {{ idsite.label ? idsite.label : title ? title : '' }} <span style="color: rgb(191, 191, 191) !important; margin-top: 5px; margin-left: 7px; font-size: 12px; display: contents;" v-if="(idsite.label ? idsite.label : title ? title : '') !== 'My sites'">(project)</span>
      </h4>
      <!-- <b-link 
        :to="{ name: 'project-sites', params: {id: idsite.idsite, name: idsite.label ? idsite.label : ''} }"
        v-else
      > -->
        <h4
          v-else
          :class="$route.name === 'view-errors' ? 'color-text-white d-flex' : 'color-text-white d-flex ml-1'"
          :style="($route.params.own === 'other' && $route.name === 'project-sites') || (idsite && idsite.idsite && idsite.label && owner === 'other' && $route.name === 'project-sites') ? 'line-height: 1.1; font-weight: 400 !important; margin-bottom: 14px;' : 'line-height: 1.1; font-weight: 400 !important; margin-left: .3rem !important;'"
          @click.prevent="$route.name === 'shared-site' ? showOpenAddSiteProject = true : logIdMembersProject(idsite.label ? idsite.label : title ? title : '', owner)"
        >
          <div :style="`min-width: 12px; width: 12px; height: 12px; border-radius: 4px; background: ${ idsite.color ? idsite.color : colorProject }; margin-top: 3px;`" class="mr-50"></div>
          {{ idsite.label ? idsite.label : title ? title : '' }} <span style="color: rgb(191, 191, 191) !important; margin-top: 5px; margin-left: 7px; font-size: 12px; display: contents;" v-if="(idsite.label ? idsite.label : title ? title : '') !== 'My sites'">(project)</span>
        </h4>
      <!-- </b-link> -->
    </div>

    <!-- Navbar description PAGE -->
    <div
      :class="$route.name === 'shared-site' ? 'navbar-container navbar-container-shared d-flex content mt-2 pb-25' : ($route.name === 'view-errors' || $route.name === 'shared-site') && !siteObj[0] && $route.params.url ? (idsite && idsite.permission === 'owner' ? 'nav-cont-header navbar-container d-flex content mt-2 pb-25' : 'navbar-container d-flex content mt-2 pb-25') : ($route.name === 'view-errors' || $route.name === 'shared-site') && siteObj[0] && !$route.params.url ? (idsite && idsite.permission === 'owner' ? 'nav-cont-header navbar-container d-flex content mt-2 pb-25' : 'navbar-container d-flex content mt-2 pb-25') : (idsite && idsite.permission === 'owner' ? 'nav-cont-header navbar-container d-flex content mt-2 pb-25' : 'navbar-container title-standard-page d-flex content mt-2 pb-25')"
      :style="$route.name === 'view-errors' ? 'margin-left: 210px; height: 110px; padding-left: 0px;' : 'margin-left: 210px; height: 90px; margin-top: 20px !important;'"
    >
      <p
        v-if="$route.name === 'project-sites'"
        class="ml-1 title-page-header"
        style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px; top: -20px; position: absolute; padding-top: 12px;"
      >
        Project
      </p>
      <b-button
        v-if="$route.name === 'edit-site'"
        variant="flat-light"
        style="color: #ffffff; display: block; text-align: left; width: 40px; position: absolute; top: -20px; padding-top: 20px;"
        class="btn-icon pl-0 mb-0 pb-25 btn-back-title-all"
        onclick="history.back()"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          size="25"
        />
      </b-button>
      <b-button
        v-if="$route.name === 'archive-sites' || $route.name === 'new-project' || $route.name === 'new-site' || $route.name === 'sites-no-project'"
        variant="flat-light"
        style="color: #ffffff; display: block; text-align: left; width: 40px; position: absolute; top: -20px; padding-top: 20px;"
        class="btn-icon pl-0 mb-0 pb-25 btn-back-title-all"
        :to="{name:'projects'}"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          size="25"
        />
      </b-button>
      <b-button
        v-if="$route.name === 'view-errors'"
        variant="flat-light"
        style="color: #ffffff; display: block; text-align: left; width: 20px; position: relative !important; top: -10px; left: -5px;"
        class="pl-0 pr-0 mb-0 pb-25 btn-back-title-err"
        onclick="history.back()"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          size="25"
        />
      </b-button>
      <b-button
        v-if="$route.name === 'edit-project'"
        variant="flat-light"
        style="color: #ffffff; display: block; text-align: left; width: 40px; position: absolute; top: -20px;"
        class="btn-icon pl-0 mb-0 pb-25 btn-back-title-edit"
        onclick="history.back()"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          size="25"
        />
      </b-button>
      <b-button
        v-if="$route.name === 'project-sites'"
        variant="flat-light"
        style="color: #ffffff; display: block; text-align: left; width: 40px; position: absolute; top: -60px; padding-top: 40px;"
        class="btn-icon pl-0 mb-0 pb-25 btn-back-title-project"
        :to="{name:'projects'}"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          size="25"
        />
      </b-button>

      <b-nav-item-dropdown
        id="dropdown-title-id"
        ref="dropdown"
        right
        toggle-class="d-flex align-items-center dropdown-user-link nav-link-custom"
        :class="$route.name === 'shared-site' ? `dropdown-title btns-right-sm-devices sm-dv sm-dv-shared ${idsite.permission === 'owner' || (planLicensed && (planLicensed !== 'free' && planLicensed !== 'Free')) ? (idsite.permission === 'owner' ? 'sm-dv-licensed sm-dv-licensed-owner' : 'sm-dv-licensed sm-dv-licensed-no-owner') : 'sm-dv-no-licensed'}` : (idsite.permission === 'owner' ? `dropdown-title btns-right-sm-devices sm-dv sm-dv-owner ${idsite.permission === 'owner' || (planLicensed && (planLicensed !== 'free' && planLicensed !== 'Free')) ? (idsite.permission === 'owner' ? 'sm-dv-licensed sm-dv-licensed-owner' : 'sm-dv-licensed sm-dv-licensed-no-owner') : 'sm-dv-no-licensed'}` : `dropdown-title btns-right-sm-devices sm-dv ${idsite.permission === 'owner' || (planLicensed && (planLicensed !== 'free' && planLicensed !== 'Free')) ? (idsite.permission === 'owner' ? 'sm-dv-licensed sm-dv-licensed-owner' : 'sm-dv-licensed sm-dv-licensed-no-owner') : 'sm-dv-no-licensed'}`)"
        :style="$route.name === 'shared-site' ? 'vertical-align: top; display: block; max-height: 90px !important; position: absolute; margin-left: 300px; top: 56px; z-index: 10;' : idsite.permission === 'owner' ? 'vertical-align: top; display: block; max-height: 90px !important; position: absolute; margin-left: 345px; top: 75px; z-index: 10;' : 'vertical-align: top; display: block; max-height: 90px !important; position: absolute; margin-left: 300px; top: 63px; z-index: 10;'"
        @click.prevent="$route.name === 'shared-site' ? openRegister() : ''"
        v-if="!siteNotFound && ($route.name === 'view-errors' || $route.name === 'shared-site')"
      >
        <template #button-content>
          <b-button
            v-b-tooltip.hover.top="idsite.permission === 'view' ? 'Have no permissions' : ''"
            :title="idsite.permission === 'view' ? 'Have no permissions' : ''"
            :style="$route.name !== 'shared-site' ? 'border: 1px solid #7838ff !important; border-radius: 10px; font-size: 16px; width: 145px; height: 32px; padding-right: 0px !important; margin-left: 8px;' : userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' || $route.name === 'shared-site' ? 'border: 1px solid #7838ff !important; border-radius: 10px; font-size: 16px; width: 145px; height: 32px; padding-right: 0px !important; margin-left: 8px;' : ' border: 1px solid #7838ff !important; border-radius: 10px; font-size: 16px; cursor: pointer !important; width: 145px; height: 46px; padding-right: 0px !important; margin-left: 8px;'"
            variant="primary"
            :class="$route.name === 'shared-site' ? 'owner-user-share-results sm-user-shared-results sm-user-share-results cursor-pointer px-25 mt-50 pr-0' : !siteObj[0] && !$route.params.url && $route.name !== 'shared-site' ? 'owner-user-share-results sm-user-share-results cursor-pointer px-25 mt-3 pr-0' : !siteObj[0] && !$route.params.url && $route.name === 'shared-site' ? 'owner-user-share-results sm-user-shared-results sm-user-share-results cursor-pointer px-25 mt-50 pr-0' : !siteNotFound && idsite.permission === 'owner' && userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' && isNotAlreadyOwner ? 'owner-user-share-results sm-user-share-results cursor-pointer px-25 pr-0' : 'sm-user-share-results cursor-pointer px-25 pr-0'"
            :disabled="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? false : true"
            v-clipboard:copy="urlActual"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            @click.prevent="getSection()"
            v-if="!urlSection || urlSection === 'overview'"
          >
            <b-img-lazy
              fluid
              :src="shareIcon"
              alt="share img"
              width="20"
              height="19"
              loading="lazy"
              class="share-img"
              :style="$route.name !== 'shared-site' && (siteObj.length && userData.uid === siteObj[0]['ownedBy'].uid) ? 'position: relative; top: -7px; left: -20px;' : userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' || $route.name === 'shared-site' ? 'position: relative; top: -8px; left: -4px;' : 'position: relative; top: -1.1px; left: -4px;'"
            />
            <span class="pl-25 share-text" :style="$route.name !== 'shared-site' && (siteObj.length && userData.uid === siteObj[0]['ownedBy'].uid) ? 'position: relative; top: -4px; left: -15px;' : userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' || $route.name === 'shared-site' ? 'position: relative; top: -4px;' : 'position: relative; top: 2px;'">Share results</span>
          </b-button>
          <b-button
            v-b-tooltip.hover.top="idsite.permission === 'view' ? 'Have no permissions' : ''"
            :title="idsite.permission === 'view' ? 'Have no permissions' : ''"
            :style="$route.name !== 'shared-site' ? 'border: 1px solid #7838ff !important; border-radius: 10px; font-size: 16px; width: 145px; height: 32px; padding-right: 0px !important; margin-left: 8px;' : userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' || $route.name === 'shared-site' ? 'border: 1px solid #7838ff !important; border-radius: 10px; font-size: 16px; width: 145px; height: 32px; padding-right: 0px !important; margin-left: 8px;' : ' border: 1px solid #7838ff !important; border-radius: 10px; font-size: 16px; cursor: pointer !important; width: 145px; height: 46px; padding-right: 0px !important; margin-left: 8px;'"
            variant="primary"
            :class="$route.name === 'shared-site' ? 'owner-user-share-results sm-user-shared-results sm-user-share-results cursor-pointer px-25 mt-50 pr-0' : !siteObj[0] && !$route.params.url && $route.name !== 'shared-site' ? 'owner-user-share-results sm-user-share-results cursor-pointer px-25 mt-3 pr-0' : !siteObj[0] && !$route.params.url && $route.name === 'shared-site' ? 'owner-user-share-results sm-user-shared-results sm-user-share-results cursor-pointer px-25 mt-50 pr-0' : !siteNotFound && idsite.permission === 'owner' && userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' && isNotAlreadyOwner ? 'owner-user-share-results sm-user-share-results cursor-pointer px-25 pr-0' : 'sm-user-share-results cursor-pointer px-25 pr-0'"
            :disabled="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? false : true"
            @click.prevent="getSection()"
            v-else
          >
            <b-img-lazy
              fluid
              :src="shareIcon"
              alt="share img"
              width="20"
              height="19"
              loading="lazy"
              class="share-img"
              :style="$route.name !== 'shared-site' && (siteObj.length && userData.uid === siteObj[0]['ownedBy'].uid) ? 'position: relative; top: -7px; left: -20px;' : userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' || $route.name === 'shared-site' ? 'position: relative; top: -8px; left: -4px;' : 'position: relative; top: -1.1px; left: -4px;'"
            />
            <span class="pl-25 share-text" :style="$route.name !== 'shared-site' && (siteObj.length && userData.uid === siteObj[0]['ownedBy'].uid) ? 'position: relative; top: -4px; left: -15px;' : userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' || $route.name === 'shared-site' ? 'position: relative; top: -4px;' : 'position: relative; top: 2px;'">Share results</span>
          </b-button>
        </template>

        <ul class="dropdown-menu dropdown-menu-right cursor-initial p-50" style="margin-top: 45px; width: 290px !important; margin-right: 5px !important; right: -10px;">
          <li
            style="font-size: 15px; cursor: default;"
            class="py-50"
          >
            <span>Share everything</span>

            <b-button
              variant="secondary"
              style="background: #28c76f !important; background-color: white !important; border: 1px solid #28c76f !important; color: white !important; padding: 4px 12px !important; border-radius: 5px !important; font-weight: 300 !important;"
              class="p-auto float-right"
              v-if="copiedEverything"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
              />
              <span class="align-middle ">link copied</span>
            </b-button>
            <b-button
              variant="secondary"
              style="background: white !important; background-color: white !important; border: 1px solid #CFD3E5 !important; color: #656B85 !important; padding: 4px 12px !important; border-radius: 5px !important; font-weight: 300 !important;"
              class="p-auto float-right"
              @click.prevent.stop="copyEverything"
              v-else
            >
              <feather-icon
                icon="CopyIcon"
                class="mr-50"
              />
              <span class="align-middle ">Copy link</span>
            </b-button>
          </li>

          <li
            style="font-size: 15px; cursor: default;"
            class="py-50"
            v-if="urlSection && urlSection !== 'overview'"
          >
            <span>Share {{ urlContent && (urlSection === 'content' || urlSection === 'errors' || urlSection === 'tools') ? (urlContent === 'mT' ? 'titles' : urlContent === 'mD' ? 'descriptions' : urlContent === 'h1' ? 'headings H1' : urlContent === 'h2' ? 'headings H2' : urlContent === 'lWc' ? 'low word count' : urlContent === 'eL' ? 'external links' : urlContent === 'st' ? 'string' : urlContent === 'gr' ? 'grammar' : urlContent === 'Img' ? 'images' : urlContent === 'dL' ? 'dead links' : urlContent === 'mA' ? 'alt tags' : urlContent === 'mI' ? 'missing images' : urlContent === 'uC' ? 'canonicals' : urlContent === 'mC' ? 'mixed content' : urlContent === 'screen' ? 'screenshots' : urlContent === 'redirect' ? 'redirections' : urlContent === 'suspT' ? 'suspicious text' : urlContent === 'imgOp' ? 'image optimization' : urlContent === 'robot' ? 'robots.txt' : urlSection) : urlSection }}</span>

            <b-button
              variant="secondary"
              style="background: #28c76f !important; background-color: white !important; border: 1px solid #28c76f !important; color: white !important; padding: 4px 12px !important; border-radius: 5px !important; font-weight: 300 !important;"
              class="p-auto float-right"
              v-if="copiedLink"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
              />
              <span class="align-middle ">link copied</span>
            </b-button>
            <b-button
              variant="secondary"
              style="background: white !important; background-color: white !important; border: 1px solid #CFD3E5 !important; color: #656B85 !important; padding: 4px 12px !important; border-radius: 5px !important; font-weight: 300 !important;"
              class="p-auto float-right"
              @click.prevent.stop="copyLink"
              v-else
            >
              <feather-icon
                icon="CopyIcon"
                class="mr-50"
              />
              <span class="align-middle ">Copy link</span>
            </b-button>
          </li>
        </ul>
      </b-nav-item-dropdown>
      
      <!-- v-if="($route.name === 'view-errors' || $route.name === 'shared-site') && $route.params.url" -->
      <b-card
        v-if="($route.name === 'view-errors' || $route.name === 'shared-site') && $route.params.url"
        no-body
        :class="$route.name === 'view-errors' ? (idsite.permission === 'owner' ? 'overflow-hidden w-100 mb-0 card-title-container-owner' : 'overflow-hidden w-100 mb-0 card-title-container') : 'overflow-hidden w-100 mb-0'"
        style="background: transparent; margin-top: 0px;"
      >
        <b-row
          no-gutters
          :class="$route.name === 'view-errors' ? 'ml-50 nav-open-tab' : 'ml-2 nav-open-tab'"
          :style="$route.name === 'view-errors' ? 'max-height: 90px;' : 'max-height: 90px;'"
        >
          <b-nav-item-dropdown
            id="dropdown-title-id"
            ref="dropdown"
            right
            toggle-class="d-flex align-items-center dropdown-user-link nav-link-custom"
            :class="$route.name === 'shared-site' ? 'dropdown-title pb-25 contain-img-head-shared' : 'dropdown-title pb-25 contain-img-head-overview'"
            style="vertical-align: top; display: block; max-height: 90px; margin-top: 5px;"
            @click.prevent="$route.name === 'shared-site' ? openRegister() : ''"
          >
            <template #button-content>
              <div @click.prevent="$route.name === 'shared-site' ? openRegister() : actionImg = true, actionText = false">
                <b-card-img-lazy
                  v-if="siteObj.length >= 1"
                  :src="siteObj.length >= 1 ? siteObj[0].imgSite : $route.params.imgSite"
                  onerror="this.onerror=null;this.src='https://src.seocloud.ai/site-thumb.png';"
                  alt="Image"
                  :style="$route.name === 'view-errors' && idsite.permission === 'owner' ? 'width: 120px !important; height: 70px;' : $route.name === 'view-errors' ? 'width: 120px !important; height: 70px;' : 'width: 120px !important; height: 70px;'"
                  class="rounded-0 img-title-header"
                />
                <b-card-img-lazy
                  v-else
                  :src="siteObj.length >= 1 ? siteObj[0].imgSite : $route.params.imgSite"
                  onerror="this.onerror=null;this.src='https://src.seocloud.ai/site-thumb.png';"
                  alt="Image"
                  :style="$route.name === 'view-errors' && idsite.permission === 'owner' ? 'width: 120px !important; height: 70px;' : $route.name === 'view-errors' ? 'width: 120px !important; height: 70px;' : 'width: 120px !important; height: 70px;'"
                  class="rounded-0 img-title-header"
                />
              </div>
          
              <div :class="$route.name === 'shared-site' ? 'ml-2 mr-1 url_links url_links-shared pr-2' : 'ml-2 mr-1 url_links pr-2'" :style="($route.name === 'view-errors' && idsite.permission === 'owner') || planLicensed ? 'position: relative; top: -9px;' : $route.name === 'view-errors' ? 'position: relative; top: -0px;' : ''"  @click.prevent="$route.name === 'shared-site' ? openRegister() : ''">
                <span
                  class="mb-0 pt-25 d-block name_site_header"
                  style="color: #FFFFFF; font-size: 15px; text-overflow: ellipsis !important; overflow: hidden !important; min-width: 160px; max-width: 350px; display: block !important; font-weight: 300 !important;"
                >{{ $route.params.name ? $route.params.name : 'site name' }}</span>
                <span
                  style="color: #A7AAB9; font-size: 15px; text-overflow: ellipsis !important; overflow: hidden !important; min-width: 160px; max-width: 350px; display: block !important;"
                  class="mb-25 url_link"
                >
                  {{ $route.params.url ? $route.params.url : 'site url' }}
                </span>
                <div class="ml-0" style="display: flex;" v-if="$route.name !== 'shared-site'">
                  <b-badge variant="light-dark" class="ml-0 mr-50" :style="statusLastCrawl === 'timeout' && listPagesSidebars.filter(ele => ele.status === 200 && !ele.cUrl.includes('.xml')).length === 0 ? 'height: 20px; text-transform: capitalize; background-color: rgb(240, 240, 241); color: rgb(255, 125, 0) !important; display: inline-block; font-size: 85%; font-weight: 400; line-height: 1; white-space: nowrap; vertical-align: baseline; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s; padding: 0.3rem 0.5rem; text-align: center; border-radius: 0.358rem; margin-top: 2px !important;' : 'height: 20px; text-transform: capitalize; background-color: rgb(240, 240, 241); color: rgb(130, 134, 139) !important; display: inline-block; font-size: 85%; font-weight: 400; line-height: 1; white-space: nowrap; vertical-align: baseline; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s; padding: 0.3rem 0.5rem; text-align: center; border-radius: 0.358rem; margin-top: 2px !important;'" v-if="(statusLastCrawl === 'timeout' && listPagesSidebars.filter(ele => ele.status === 200 && !ele.cUrl.includes('.xml')).length === 0) || (idsite.permission && idsite.permission === 'owner')">{{statusLastCrawl === 'timeout' && listPagesSidebars.filter(ele => ele.status === 200 && !ele.cUrl.includes('.xml')).length === 0 ? 'Timeout' : idsite.permission === 'edit' ? 'Editor' : idsite.permission === 'view' ? 'Viewer' : idsite.permission}}</b-badge>
                  <b-badge variant="light-dark" class="ml-0 mr-50" :style="'height: 20px; text-transform: capitalize; background-color: rgb(240, 240, 241); color: rgb(130, 134, 139) !important; display: inline-block; font-size: 85%; font-weight: 400; line-height: 1; white-space: nowrap; vertical-align: baseline; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s; padding: 0.3rem 0.5rem; text-align: center; border-radius: 0.358rem; margin-top: 2px !important;'" @click.prevent.stop="getSidebarInfoSubscription()" v-if="planLicensed">License: {{planLicensed}}{{planLicensed === 'free' || planLicensed === 'Free' ? '' : ' pages'}}</b-badge>
                  <b-button
                    style="background: transparent !important; border: 0px; display: inline !important; margin-top: 3px !important; color: rgb(247, 185, 185) !important; font-weight: 300;"
                    class="pt-0 pr-0 pb-0 ml-0 pl-0"
                    @click.prevent.stop="$route.name === 'shared-site' && userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? openRegister() : getSidebarInfoSubscription()"
                    v-if="cancelSub"
                  >
                    <feather-icon
                      icon="AlertCircleIcon"
                      class="cursor-pointer pl-0 ml-0"
                      style="color: rgb(247, 185, 185) !important;"
                      size="15"
                    />
                  </b-button>
                  <b-button
                    style="background: transparent !important; border: 0px; display: inline !important; margin-top: 3px !important; color: rgb(247, 185, 185) !important; font-weight: 300;"
                    class="pt-0 pr-0 pb-0 ml-0 pl-0"
                    @click.prevent.stop="$route.name === 'shared-site' && userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? openRegister() : logIdSite(siteObj)"
                    v-if="!isNotAlreadyOwner"
                  >
                    <feather-icon
                      icon="AlertCircleIcon"
                      class="cursor-pointer pl-0 ml-0"
                      style="color: rgb(247, 185, 185) !important;"
                      size="15"
                    /> Site disconnected
                  </b-button>
                </div>

                <div v-if="($route.name === 'view-errors' || $route.name === 'shared-site') && !isDevSite" style="width: 400px !important; display: flex; margin-top: 5px;" class="btns-right-sm-devices">
                  <b-button
                    :style="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? 'background-color: #fff !important; border: 1px solid #fff !important; border-radius: 10px; font-size: 16px; max-width: 125px; width: 125px; background: #fff !important; border-color: #fff !important; color: #6c6c6c !important; text-align: left; height: 32px;' : 'background-color: #fff !important; border: 1px solid #fff !important; border-radius: 10px; font-size: 16px; cursor: not-allowed !important; max-width: 125px; width: 125px; background: #fff !important; border-color: #fff !important; color: #6c6c6c !important; text-align: left; height: 32px;'"
                    size="sm"
                    variant="primary"
                    :class="idsite.permission === 'view' ? 'add-sm-btn-shared cursor-no-drop' : 'add-sm-btn-shared cursor-pointer'"
                    @click.stop.prevent="$route.name === 'shared-site' && userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? showOpenAddSiteProject = true : $route.name === 'shared-site' && userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? openRegister() : null"
                    :disabled="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? false : true"
                    v-if="$route.name === 'shared-site' && !siteNotFound"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="16"
                      style="position: relative; top: 0.3px; left: 1.5px;"
                      class=""
                    />
                    <span style="margin-left: 11px; position: relative; top: -1px;">Add site</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :style="'display: flex; max-width: 185px; width: 185px; background: #2B3563 !important; border-color: #2B3563 !important; border-radius: 5px; height: 32px; padding-top: 7px !important;'"
                    class="px-0 pl-25 pt-50 copilot-title-owner"
                    v-else-if="!siteNotFound && idsite.permission === 'owner' && userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' && isNotAlreadyOwner"
                    @click.stop.prevent="$route.name === 'shared-site' && userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? openRegister() : showOpenSidebarProPages = 'true'"
                  >
                    <span
                      class=""
                      style="color: #FFFFFF; font-size: 15px; font-weight: 300; font-style: normal; position: relative; left: 2px;"
                    >
                      <b-img-lazy
                        fluid
                        :src="proIcon"
                        alt="pro page img"
                        width="17"
                        height="16"
                        style="margin-bottom: 7px; margin-left: 7px;"
                        class="mr-0 ml-0"
                        loading="lazy"
                      />
                      <span style="margin-left: 10px;">SEOcloud copilot</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="16"
                        style="margin-top: 2px; float: right; margin-left: 8px;"
                        class=""
                      />
                    </span>
                  </b-button>
                  <div v-else-if="(idsite && ($route.name === 'view-errors' || $route.name === 'shared-site') && siteObj.length && (userData.uid !== siteObj[0]['ownedBy'].uid || !siteObj[0]['ownedBy'] || !siteObj[0]['ownedBy'].uid)) || !isNotAlreadyOwner" class="">
                    <b-button
                      v-b-tooltip.hover.top="idsite.permission === 'view' ? 'Have no permissions' : ''"
                      :title="idsite.permission === 'view' ? 'Have no permissions' : ''"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :style="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? 'background-color: #fff !important; border: 1px solid #fff !important; border-radius: 10px; font-size: 16px; max-width: 125px; width: 125px; background: #fff !important; border-color: #fff !important; color: #6c6c6c !important; height: 32px; padding-top: 6px;' : 'background-color: #fff !important; border: 1px solid #fff !important; border-radius: 10px; font-size: 16px; cursor: not-allowed !important; max-width: 125px; width: 125px; background: #fff !important; border-color: #fff !important; color: #6c6c6c !important; height: 32px; padding-top: 6px;'"
                      size="sm"
                      variant="primary"
                      :class="idsite.permission === 'view' ? 'sm-btn-claim cursor-no-drop' : 'sm-btn-claim cursor-pointer'"
                      @click.stop.prevent="$route.name === 'shared-site' && userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? openRegister() : logIdSite(siteObj)"
                      :disabled="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? false : true"
                    >
                    Claim site
                    </b-button>
                  </div>
                </div>
              </div>
            </template>
            
            <ul class="dropdown-menu dropdown-menu-right dropdown-menu-right-open-tab" :style="actionImg ? ($route.params.own === 'other' ? 'right: calc(100% + 115px) !important; height: 78px !important; top: -3px; padding-top: 5px !important;' : 'right: calc(100% + 55px) !important; height: 78px !important; top: -3px; padding-top: 5px !important;') : ($route.params.own === 'other' ? 'right: calc(100% + -31px) !important; height: 78px !important; top: -3px; padding-top: 5px !important;' : 'right: calc(100% - 88px) !important; height: 78px !important; top: -3px; padding-top: 5px !important;')" v-if="$route.name !== 'shared-site'">
              <b-dropdown-item
                :href="$route.params.url.includes('http:') || $route.params.url.includes('https:') ? $route.params.url : 'http://'+$route.params.url"
                link-class="d-flex align-items-center item-dropdown-user"
                style="font-size: 13px; line-height: 18px;"
                target="_blank"
              >
                <span>Open in new tab</span>
              </b-dropdown-item>

              <b-dropdown-form class="px-0 dropdown-second-line-form">
                <b-button
                  :to="{ name: 'edit-site', params: { id: $route.params.id, project: $route.params.project ? $route.params.project : $route.params.id } }"
                  variant="flat-light"
                  class="p-0 btn-dropdown-menu-header"
                  style="font-size: 13px; line-height: 18px; padding: 8px 0px 14px 0px !important; color: #656b85; background: transparent !important; border: 0px !important; margin-left: 10px; margin-right: 6px;"
                >
                  <span>Edit site</span>
                </b-button>
                
                <svg xmlns="http://www.w3.org/2000/svg" width="1" height="20" class="">
                  <line x1="0" y1="0" x2="0" y2="30" stroke="#d1d1d1" stroke-width="1" />
                </svg>

                <b-button
                  @click.prevent="showOpenArchiveSite = true"
                  variant="flat-light"
                  class="p-0 btn-dropdown-menu-header"
                  style="font-size: 13px; line-height: 18px; padding: 8px 0px 14px 0px !important; color: #656b85; background: transparent !important; border: 0px !important; margin-left: 7px;"
                >
                  <span>Archive</span>
                </b-button>
              </b-dropdown-form>
            </ul>
          </b-nav-item-dropdown>
        </b-row>
      </b-card>
      <b-card
        v-else-if="($route.name === 'view-errors' || $route.name === 'shared-site') && validH"
        no-body
        :class="$route.name === 'view-errors' ? (idsite.permission === 'owner' ? 'overflow-hidden w-100 mb-0 card-title-container-owner' : 'overflow-hidden w-100 mb-0 card-title-container') : 'overflow-hidden w-100 mb-0'"
        style="background: transparent; margin-top: 0px;"
      >
      <!-- v-else-if="($route.name === 'view-errors' || $route.name === 'shared-site') && siteObj[0]" -->
        <b-row
          no-gutters
          :class="$route.name === 'view-errors' ? 'ml-50 nav-open-tab' : 'ml-2 nav-open-tab'"
          :style="$route.name === 'view-errors' ? 'max-height: 90px;' : 'max-height: 90px;'"
        >
          <b-nav-item-dropdown
            id="dropdown-title-id"
            ref="dropdown"
            right
            toggle-class="d-flex align-items-center dropdown-user-link nav-link-custom"
            :class="$route.name === 'shared-site' ? 'dropdown-title pb-25 contain-img-head-shared' : 'dropdown-title pb-25 contain-img-head-overview'"
            style="vertical-align: top; display: block; max-height: 90px; margin-top: 5px;"
            @click.prevent="$route.name === 'shared-site' ? openRegister() : ''"
          >
            <template #button-content>
              <div @click.prevent="$route.name === 'shared-site' ? openRegister() : actionImg = true, actionText = false">
                <b-card-img-lazy
                  v-if="siteObj.length >= 1"
                  :src="siteObj.length >= 1 ? siteObj[0].imgSite : $route.query.imgSite ? $route.query.imgSite : 'https://src.seocloud.ai/site-thumb.png'"
                  onerror="this.onerror=null;this.src='https://src.seocloud.ai/site-thumb.png';"
                  alt="Image"
                  :style="$route.name === 'view-errors' && idsite.permission === 'owner' ? 'width: 120px !important; height: 70px;' : $route.name === 'view-errors' ? 'width: 120px !important; height: 70px;' : 'width: 120px !important; height: 70px;'"
                  class="rounded-0 img-title-header"
                />
                <b-card-img-lazy
                  v-else
                  :src="siteObj.length >= 1 ? siteObj[0].imgSite : $route.query.imgSite ? $route.query.imgSite : 'https://src.seocloud.ai/site-thumb.png'"
                  onerror="this.onerror=null;this.src='https://src.seocloud.ai/site-thumb.png';"
                  alt="Image"
                  :style="$route.name === 'view-errors' && idsite.permission === 'owner' ? 'width: 120px !important; height: 70px;' : $route.name === 'view-errors' ? 'width: 120px !important; height: 70px;' : 'width: 120px !important; height: 70px;'"
                  class="rounded-0 img-title-header"
                />
              </div>
              <div :class="$route.name === 'shared-site' ? 'ml-2 mr-1 url_links url_links-shared pr-2' : 'ml-2 mr-1 url_links pr-2'" :style="($route.name === 'view-errors' && idsite.permission === 'owner') || planLicensed ? 'position: relative; top: -9px;' : $route.name === 'view-errors' ? 'position: relative; top: -0px;' : ''" @click.prevent="$route.name === 'shared-site' ? openRegister() : ''">
                <span
                  class="mb-0 pt-25 d-block name_site_header"
                  style="color: #FFFFFF; font-size: 15px; text-overflow: ellipsis !important; overflow: hidden !important; min-width: 160px; max-width: 350px; display: block !important; font-weight: 300 !important;"
                >{{ siteObj.length >= 1 ? siteObj[0].name : 'site name' }}</span>
                <span
                  style="color: #A7AAB9; font-size: 15px; text-overflow: ellipsis !important; overflow: hidden !important; min-width: 160px; max-width: 350px; display: block !important;"
                  class="mb-25 url_link"
                >
                  {{ siteObj.length >= 1 ? siteObj[0].domain : 'site url' }}
                </span>
                <div class="ml-0" style="display: flex;" v-if="$route.name !== 'shared-site'">
                  <b-badge variant="light-dark" class="ml-0 mr-50" :style="statusLastCrawl === 'timeout' && listPagesSidebars.filter(ele => ele.status === 200 && !ele.cUrl.includes('.xml')).length === 0 ? 'height: 20px; text-transform: capitalize; background-color: rgb(240, 240, 241); color: rgb(255, 125, 0) !important; display: inline-block; font-size: 85%; font-weight: 400; line-height: 1; white-space: nowrap; vertical-align: baseline; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s; padding: 0.3rem 0.5rem; text-align: center; border-radius: 0.358rem; margin-top: 2px !important;' : 'height: 20px; text-transform: capitalize; background-color: rgb(240, 240, 241); color: rgb(130, 134, 139) !important; display: inline-block; font-size: 85%; font-weight: 400; line-height: 1; white-space: nowrap; vertical-align: baseline; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s; padding: 0.3rem 0.5rem; text-align: center; border-radius: 0.358rem; margin-top: 2px !important;'" v-if="(statusLastCrawl === 'timeout' && listPagesSidebars.filter(ele => ele.status === 200 && !ele.cUrl.includes('.xml')).length === 0) || (idsite.permission && idsite.permission === 'owner')">{{statusLastCrawl === 'timeout' && listPagesSidebars.filter(ele => ele.status === 200 && !ele.cUrl.includes('.xml')).length === 0 ? 'Timeout' : idsite.permission === 'edit' ? 'Editor' : idsite.permission === 'view' ? 'Viewer' : idsite.permission}}</b-badge>
                  <b-badge variant="light-dark" class="ml-0 mr-50" :style="'height: 20px; text-transform: capitalize; background-color: rgb(240, 240, 241); color: rgb(130, 134, 139) !important; display: inline-block; font-size: 85%; font-weight: 400; line-height: 1; white-space: nowrap; vertical-align: baseline; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s, border 0s; padding: 0.3rem 0.5rem; text-align: center; border-radius: 0.358rem; margin-top: 2px !important;'" @click.prevent.stop="getSidebarInfoSubscription()" v-if="planLicensed">License: {{planLicensed}}{{planLicensed === 'free' || planLicensed === 'Free' ? '' : ' pages'}}</b-badge>
                  <b-button
                    style="background: transparent !important; border: 0px; display: inline !important; margin-top: 3px !important; color: rgb(247, 185, 185) !important; font-weight: 300;"
                    class="pt-0 pr-0 pb-0 ml-0 pl-0"
                    @click.prevent.stop="$route.name === 'shared-site' && userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? openRegister() : getSidebarInfoSubscription()"
                    v-if="cancelSub"
                  >
                    <feather-icon
                      icon="AlertCircleIcon"
                      class="cursor-pointer pl-0 ml-0"
                      style="color: rgb(247, 185, 185) !important;"
                      size="15"
                    />
                  </b-button>
                  <b-button
                    style="background: transparent !important; border: 0px; display: inline !important; margin-top: 3px !important; color: rgb(247, 185, 185) !important; font-weight: 300;"
                    class="pt-0 pr-0 pb-0 ml-0 pl-0"
                    @click.prevent.stop="$route.name === 'shared-site' && userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? openRegister() : logIdSite(siteObj)"
                    v-if="!isNotAlreadyOwner"
                  >
                    <feather-icon
                      icon="AlertCircleIcon"
                      class="cursor-pointer pl-0 ml-0"
                      style="color: rgb(247, 185, 185) !important;"
                      size="15"
                    /> Site disconnected
                  </b-button>
                </div>

                <div v-if="($route.name === 'view-errors' || $route.name === 'shared-site') && !isDevSite" style="width: 400px !important; display: flex; margin-top: 5px;" class="btns-right-sm-devices">
                  <b-button
                    :style="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? 'background-color: #fff !important; border: 1px solid #fff !important; border-radius: 10px; font-size: 16px; max-width: 125px; width: 125px; background: #fff !important; border-color: #fff !important; color: #6c6c6c !important; text-align: left; height: 32px;' : 'background-color: #fff !important; border: 1px solid #fff !important; border-radius: 10px; font-size: 16px; cursor: not-allowed !important; max-width: 125px; width: 125px; background: #fff !important; border-color: #fff !important; color: #6c6c6c !important; text-align: left; height: 32px;'"
                    size="sm"
                    variant="primary"
                    :class="idsite.permission === 'view' ? 'add-sm-btn-shared cursor-no-drop' : 'add-sm-btn-shared cursor-pointer'"
                    @click.stop.prevent="$route.name === 'shared-site' && userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? showOpenAddSiteProject = true : $route.name === 'shared-site' && userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? openRegister() : null"
                    :disabled="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? false : true"
                    v-if="$route.name === 'shared-site' && !siteNotFound"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="16"
                      style="position: relative; top: 0.3px; left: 1.5px;"
                      class=""
                    />
                    <span style="margin-left: 11px; position: relative; top: -1px;">Add site</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :style="'display: flex; max-width: 185px; width: 185px; background: #2B3563 !important; border-color: #2B3563 !important; border-radius: 5px; height: 32px; padding-top: 7px !important;'"
                    class="px-0 pl-25 pt-50 copilot-title-owner"
                    v-else-if="!siteNotFound && idsite.permission === 'owner' && userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2'"
                    @click.stop.prevent="$route.name === 'shared-site' && userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? openRegister() : showOpenSidebarProPages = 'true'"
                  >
                    <span
                      class=""
                      style="color: #FFFFFF; font-size: 15px; font-weight: 300; font-style: normal; position: relative; left: 2px;"
                    >
                      <b-img-lazy
                        fluid
                        :src="proIcon"
                        alt="pro page img"
                        width="17"
                        height="16"
                        style="margin-bottom: 7px; margin-left: 7px;"
                        class="mr-0 ml-0"
                        loading="lazy"
                      />
                      <span style="margin-left: 10px;">SEOcloud copilot</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="16"
                        style="margin-bottom: 5px; float: right; margin-left: 8px;"
                        class=""
                      />
                    </span>
                  </b-button>
                  <div v-else-if="idsite && ($route.name === 'view-errors' || $route.name === 'shared-site') && siteObj.length" class="">
                    <b-button
                      v-if="(userData.uid !== siteObj[0]['ownedBy'].uid || !siteObj[0]['ownedBy'] || !siteObj[0]['ownedBy'].uid)"
                      v-b-tooltip.hover.top="idsite.permission === 'view' ? 'Have no permissions' : ''"
                      :title="idsite.permission === 'view' ? 'Have no permissions' : ''"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :style="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? 'background-color: #fff !important; border: 1px solid #fff !important; border-radius: 10px; font-size: 16px; max-width: 125px; width: 125px; background: #fff !important; border-color: #fff !important; color: #6c6c6c !important; height: 32px; padding-top: 6px;' : 'background-color: #fff !important; border: 1px solid #fff !important; border-radius: 10px; font-size: 16px; cursor: not-allowed !important; max-width: 125px; width: 125px; background: #fff !important; border-color: #fff !important; color: #6c6c6c !important; height: 32px; padding-top: 6px;'"
                      size="sm"
                      variant="primary"
                      :class="idsite.permission === 'view' ? 'sm-btn-claim cursor-no-drop' : 'sm-btn-claim cursor-pointer'"
                      @click.stop.prevent="$route.name === 'shared-site' && userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? openRegister() : logIdSite(siteObj)"
                      :disabled="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? false : true"
                    >
                    Claim site
                    </b-button>
                  </div>
                </div>
              </div>
            </template>
            
            <ul class="dropdown-menu dropdown-menu-right dropdown-menu-right-open-tab" :style="actionImg ? (siteObj.length >= 1 && siteObj[0].id && siteObj[0].name && owner === 'other' ? 'right: calc(100% + 115px) !important; height: 78px !important; top: -3px; padding-top: 5px !important;' : 'right: calc(100% + 55px) !important; height: 78px !important; top: -3px; padding-top: 5px !important;') : (siteObj.length >= 1 && siteObj[0].id && siteObj[0].name && owner === 'other' ? 'right: calc(100% + -31px) !important; height: 78px !important; top: -3px; padding-top: 5px !important;' : 'right: calc(100% - 88px) !important; height: 78px !important; top: -3px; padding-top: 5px !important;')" v-if="$route.name !== 'shared-site'">
              <b-dropdown-item
                :href="siteObj.length >= 1 && siteObj[0].domain ? siteObj[0].domain : '#'"
                link-class="d-flex align-items-center item-dropdown-user"
                style="font-size: 13px; line-height: 18px;"
                target="_blank"
              >
                <span>Open in new tab</span>
              </b-dropdown-item>

              <b-dropdown-form class="px-0 dropdown-second-line-form">
                <b-button
                  :to="{ name: 'edit-site', params: { id: siteObj.length >= 1 && siteObj[0].id ? siteObj[0].id : '', project: siteObj.length >= 1 && siteObj[0].projectid ? siteObj[0].projectid : $route.params.project ? $route.params.project : '' } }"
                  variant="flat-light"
                  class="p-0 btn-dropdown-menu-header"
                  style="font-size: 13px; line-height: 18px; padding: 8px 0px 14px 0px !important; color: #656b85; background: transparent !important; border: 0px !important; margin-left: 10px; margin-right: 6px;"
                >
                  <span>Edit site</span>
                </b-button>

                <svg xmlns="http://www.w3.org/2000/svg" width="1" height="20" class="">
                  <line x1="0" y1="0" x2="0" y2="30" stroke="#d1d1d1" stroke-width="1" />
                </svg>

                <b-button
                  @click.prevent="showOpenArchiveSite = true"
                  variant="flat-light"
                  class="p-0 btn-dropdown-menu-header"
                  style="font-size: 13px; line-height: 18px; padding: 8px 0px 14px 0px !important; color: #656b85; background: transparent !important; border: 0px !important; margin-left: 7px;"
                >
                  <span>Archive</span>
                </b-button>
              </b-dropdown-form>
            </ul>
          </b-nav-item-dropdown>
        </b-row>
      </b-card>
      
      <!-- <h1
        v-if="($route.name === 'view-errors') && !siteObj[0] && !$route.params.url"
        class="color-text-white d-flex ml-1"
        :style="($route.params.own === 'other' && $route.name === 'project-sites') || (idsite && idsite.idsite && idsite.label && owner === 'other' && $route.name === 'project-sites') ? 'font-weight: 400 !important; margin-bottom: 14px; padding-top: 35px; z-index: 10;' : 'font-weight: 400 !important; padding-top: 35px; z-index: 10;'"
        @click.prevent="logIdMembersProject($route.meta.pageTitle ? $route.meta.pageTitle : $route.params.name ? $route.params.name : title, owner)"
      >
        <div v-if="$route.name === 'project-sites'" :style="`width: 12px; height: 12px; border-radius: 4px; background: ${colorProject}; margin-top: 12px;`" class="mr-50"></div>
        {{ $route.meta.pageTitle ? $route.meta.pageTitle : $route.params.name ? $route.params.name : title }} <span style="color: rgb(191, 191, 191) !important; margin-top: 5px; margin-left: 7px; font-size: 12px;" v-if="($route.meta.pageTitle ? $route.meta.pageTitle : $route.params.name ? $route.params.name : title) !== 'My sites'">(project)</span>
      </h1> -->
      <h1
        v-if="$route.name !== 'view-errors' && $route.name !== 'shared-site'"
        class="color-text-white d-flex ml-1 title-page-header"
        :style="($route.params.own === 'other' && $route.name === 'project-sites') || (idsite && idsite.idsite && idsite.label && owner === 'other' && $route.name === 'project-sites') ? 'font-weight: 400 !important; margin-bottom: 14px; padding-top: 12px;' : 'font-weight: 400 !important; padding-top: 12px;'"
      >
        <div v-if="$route.name === 'project-sites'" :style="`width: 12px; min-width: 12px; height: 12px; border-radius: 4px; background: ${colorProject}; margin-top: 12px;`" class="mr-50"></div>
        {{ $route.name === 'project-sites' ? (title ? title : $route.meta.pageTitle ? $route.meta.pageTitle : $route.params.name ? $route.params.name : title) : ($route.meta.pageTitle ? $route.meta.pageTitle : $route.params.name ? $route.params.name : title)}}
      </h1>

      <div v-if="$route.name === 'edit-site' || $route.name === 'archive-sites' || $route.name === 'new-project' || $route.name === 'new-site' || $route.name === 'sites-no-project' || $route.name === 'project-sites' || $route.name === 'view-errors' || $route.name === 'edit-project' || $route.name === 'shared-site'" :class="$route.name === 'view-errors' ? 'btns-right-xl-devices' : 'btns-right-xl-devices-shared'">
        <div v-if="idsite && ($route.name === 'view-errors' || $route.name === 'shared-site')" :style="$route.name === 'view-errors' ? 'margin-top: 20px;' : 'margin-top: 0px;'" class="">
          <b-button
            :style="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? 'background-color: #fff !important; border: 1px solid #fff !important; border-radius: 10px; font-size: 16px; max-width: 145px; width: 145px; background: #fff !important; border-color: #fff !important; color: #6c6c6c !important; text-align: left; height: 32px;' : 'background-color: #fff !important; border: 1px solid #fff !important; border-radius: 10px; font-size: 16px; cursor: not-allowed !important; max-width: 145px; width: 145px; background: #fff !important; border-color: #fff !important; color: #6c6c6c !important; text-align: left; height: 32px;'"
            size="sm"
            variant="primary"
            :class="idsite.permission === 'view' ? 'add-sm-btn-shared cursor-no-drop ml-50' : 'add-sm-btn-shared cursor-pointer ml-50'"
            @click="$route.name === 'shared-site' && userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? showOpenAddSiteProject = true : $route.name === 'shared-site' && userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? openRegister() : null"
            :disabled="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? false : true"
            v-if="$route.name === 'shared-site' && !siteNotFound"
          >
            <feather-icon
              icon="PlusIcon"
              size="16"
              style="position: relative; top: 0.3px; left: 1.5px;"
              class=""
            />
            <span style="margin-left: 11px;">Add site</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :style="'margin-left: 8px !important; display: flex; max-width: 185px; width: 185px; background: #2B3563 !important; border-color: #2B3563 !important; border-radius: 5px; height: 32px;'"
            class="px-0 pl-25 pt-50 ml-1 copilot-title-owner"
            v-else-if="!siteNotFound && idsite.permission === 'owner' && userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' && isNotAlreadyOwner"
            @click="$route.name === 'shared-site' && userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? openRegister() : showOpenSidebarProPages = 'true'"
          >
            <span
              class=""
              style="color: #FFFFFF; font-size: 15px; font-weight: 300; font-style: normal; position: relative; left: 2px;"
            >
              <b-img-lazy
                fluid
                :src="proIcon"
                alt="pro page img"
                width="17"
                height="16"
                style="margin-bottom: 7px; margin-left: 7px;"
                class="mr-0 ml-0"
                loading="lazy"
              />
              <span style="margin-left: 10px;">SEOcloud copilot</span>
              <feather-icon
                icon="ChevronDownIcon"
                size="16"
                style="margin-top: 2px; float: right; margin-left: 8px;"
                class=""
              />
            </span>
          </b-button>
          <div v-else-if="(idsite && ($route.name === 'view-errors' || $route.name === 'shared-site') && siteObj.length && (userData.uid !== siteObj[0]['ownedBy'].uid || !siteObj[0]['ownedBy'] || !siteObj[0]['ownedBy'].uid)) || !isNotAlreadyOwner" class="">
            <b-button
              v-b-tooltip.hover.top="idsite.permission === 'view' ? 'Have no permissions' : ''"
              :title="idsite.permission === 'view' ? 'Have no permissions' : ''"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :style="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? 'margin-left: 8px !important; background-color: #fff !important; border: 1px solid #fff !important; border-radius: 10px; font-size: 16px; max-width: 185px; width: 185px; background: #fff !important; border-color: #fff !important; color: #6c6c6c !important;' : 'margin-left: 8px !important; background-color: #fff !important; border: 1px solid #fff !important; border-radius: 10px; font-size: 16px; cursor: not-allowed !important; max-width: 185px; width: 185px; background: #fff !important; border-color: #fff !important; color: #6c6c6c !important;'"
              size="sm"
              variant="primary"
              :class="idsite.permission === 'view' ? 'sm-btn-claim cursor-no-drop' : 'sm-btn-claim cursor-pointer'"
              @click="$route.name === 'shared-site' && userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? openRegister() : logIdSite(siteObj)"
              :disabled="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? false : true"
            >
            Claim site
            </b-button>
          </div>
          <b-nav-item-dropdown
            id="dropdown-title-id"
            ref="dropdown"
            right
            toggle-class="d-flex align-items-center dropdown-user-link nav-link-custom"
            class="dropdown-title"
            style="vertical-align: top; display: block; max-height: 90px !important;"
            @click.prevent="$route.name === 'shared-site' ? openRegister() : ''"
            v-if="!siteNotFound"
          >
            <template #button-content>
              <b-button
                v-b-tooltip.hover.top="idsite.permission === 'view' ? 'Have no permissions' : ''"
                :title="idsite.permission === 'view' ? 'Have no permissions' : ''"
                :style="$route.name !== 'shared-site' ? 'border: 1px solid #7838ff !important; border-radius: 10px; font-size: 16px; width: 185px; height: 32px; padding-right: 0px !important; margin-left: 8px;' : userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' || $route.name === 'shared-site' ? 'border: 1px solid #7838ff !important; border-radius: 10px; font-size: 16px; width: 145px; height: 32px; padding-right: 0px !important; margin-left: 8px;' : ' border: 1px solid #7838ff !important; border-radius: 10px; font-size: 16px; cursor: pointer !important; width: 185px; height: 46px; padding-right: 0px !important; margin-left: 8px;'"
                variant="primary"
                :class="$route.name === 'shared-site' ? 'owner-user-share-results sm-user-shared-results sm-user-share-results cursor-pointer px-25 mt-50 pr-0' : !siteObj[0] && !$route.params.url && $route.name !== 'shared-site' ? 'owner-user-share-results sm-user-share-results cursor-pointer px-25 mt-3 pr-0' : !siteObj[0] && !$route.params.url && $route.name === 'shared-site' ? 'owner-user-share-results sm-user-shared-results sm-user-share-results cursor-pointer px-25 mt-50 pr-0' : !siteNotFound && idsite.permission === 'owner' && userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' && isNotAlreadyOwner ? 'owner-user-share-results sm-user-share-results cursor-pointer px-25 mt-50 pr-0' : 'sm-user-share-results cursor-pointer px-25 mt-50 pr-0'"
                :disabled="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? false : true"
                v-clipboard:copy="urlActual"
                v-clipboard:success="onCopy"
                v-clipboard:error="onError"
                @click.prevent="getSection()"
                v-if="!urlSection || urlSection === 'overview'"
              >
                <b-img-lazy
                  fluid
                  :src="shareIcon"
                  alt="share img"
                  width="20"
                  height="19"
                  loading="lazy"
                  class="share-img"
                  :style="$route.name !== 'shared-site' && (siteObj.length && userData.uid === siteObj[0]['ownedBy'].uid) ? 'position: relative; top: -7px; left: -20px;' : userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' || $route.name === 'shared-site' ? 'position: relative; top: -7px; left: -4px;' : 'position: relative; top: -1.1px; left: -4px;'"
                />
                <span class="pl-25 share-text" :style="$route.name !== 'shared-site' && (siteObj.length && userData.uid === siteObj[0]['ownedBy'].uid) ? 'position: relative; top: -4px; left: -15px;' : userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' || $route.name === 'shared-site' ? 'position: relative; top: -4px;' : 'position: relative; top: 2px;'">Share results</span>
              </b-button>
              <b-button
                v-b-tooltip.hover.top="idsite.permission === 'view' ? 'Have no permissions' : ''"
                :title="idsite.permission === 'view' ? 'Have no permissions' : ''"
                :style="$route.name !== 'shared-site' ? 'border: 1px solid #7838ff !important; border-radius: 10px; font-size: 16px; width: 185px; height: 32px; padding-right: 0px !important; margin-left: 8px;' : userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' || $route.name === 'shared-site' ? 'border: 1px solid #7838ff !important; border-radius: 10px; font-size: 16px; width: 145px; height: 32px; padding-right: 0px !important; margin-left: 8px;' : ' border: 1px solid #7838ff !important; border-radius: 10px; font-size: 16px; cursor: pointer !important; width: 145px; height: 46px; padding-right: 0px !important; margin-left: 8px;'"
                variant="primary"
                :class="$route.name === 'shared-site' ? 'owner-user-share-results sm-user-shared-results sm-user-share-results cursor-pointer px-25 mt-50 pr-0' : !siteObj[0] && !$route.params.url && $route.name !== 'shared-site' ? 'owner-user-share-results sm-user-share-results cursor-pointer px-25 mt-3 pr-0' : !siteObj[0] && !$route.params.url && $route.name === 'shared-site' ? 'owner-user-share-results sm-user-shared-results sm-user-share-results cursor-pointer px-25 mt-50 pr-0' : !siteNotFound && idsite.permission === 'owner' && userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' && isNotAlreadyOwner ? 'owner-user-share-results sm-user-share-results cursor-pointer px-25 mt-50 pr-0' : 'sm-user-share-results cursor-pointer px-25 mt-50 pr-0'"
                :disabled="idsite.permission === 'owner' || idsite.permission === 'admin' || idsite.permission === 'edit' ? false : true"
                @click.prevent="getSection()"
                v-else
              >
                <b-img-lazy
                  fluid
                  :src="shareIcon"
                  alt="share img"
                  width="20"
                  height="19"
                  loading="lazy"
                  class="share-img"
                  :style="$route.name !== 'shared-site' && (siteObj.length && userData.uid === siteObj[0]['ownedBy'].uid) ? 'position: relative; top: -7px; left: -20px;' : userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' || $route.name === 'shared-site' ? 'position: relative; top: -7px; left: -4px;' : 'position: relative; top: -1.1px; left: -4px;'"
                />
                <span class="pl-25 share-text" :style="$route.name !== 'shared-site' && (siteObj.length && userData.uid === siteObj[0]['ownedBy'].uid) ? 'position: relative; top: -4px; left: -15px;' : userData.uid !== 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' || $route.name === 'shared-site' ? 'position: relative; top: -4px;' : 'position: relative; top: 2px;'">Share results</span>
              </b-button>
            </template>

            <ul class="dropdown-menu dropdown-menu-right cursor-initial p-50" style="margin-top: 55px !important; width: 290px !important; margin-right: 5px !important;">
              <li
                style="font-size: 15px; cursor: default;"
                class="py-50"
              >
                <span>Share everything</span>

                <b-button
                  variant="secondary"
                  style="background: #28c76f !important; background-color: white !important; border: 1px solid #28c76f !important; color: white !important; padding: 4px 12px !important; border-radius: 5px !important; font-weight: 300 !important;"
                  class="p-auto float-right"
                  v-if="copiedEverything"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                  />
                  <span class="align-middle ">link copied</span>
                </b-button>
                <b-button
                  variant="secondary"
                  style="background: white !important; background-color: white !important; border: 1px solid #CFD3E5 !important; color: #656B85 !important; padding: 4px 12px !important; border-radius: 5px !important; font-weight: 300 !important;"
                  class="p-auto float-right"
                  @click.prevent.stop="copyEverything"
                  v-else
                >
                  <feather-icon
                    icon="CopyIcon"
                    class="mr-50"
                  />
                  <span class="align-middle ">Copy link</span>
                </b-button>
              </li>

              <li
                style="font-size: 15px; cursor: default;"
                class="py-50"
                v-if="urlSection && urlSection !== 'overview'"
              >
                <span>Share {{ urlContent && (urlSection === 'content' || urlSection === 'errors' || urlSection === 'tools') ? (urlContent === 'mT' ? 'titles' : urlContent === 'mD' ? 'descriptions' : urlContent === 'h1' ? 'headings H1' : urlContent === 'h2' ? 'headings H2' : urlContent === 'lWc' ? 'low word count' : urlContent === 'eL' ? 'external links' : urlContent === 'st' ? 'string' : urlContent === 'gr' ? 'grammar' : urlContent === 'Img' ? 'images' : urlContent === 'dL' ? 'dead links' : urlContent === 'mA' ? 'alt tags' : urlContent === 'mI' ? 'missing images' : urlContent === 'uC' ? 'canonicals' : urlContent === 'mC' ? 'mixed content' : urlContent === 'screen' ? 'screenshots' : urlContent === 'redirect' ? 'redirections' : urlContent === 'suspT' ? 'suspicious text' : urlContent === 'imgOp' ? 'image optimization' : urlContent === 'robot' ? 'robots.txt' : urlSection) : urlSection }}</span>

                <b-button
                  variant="secondary"
                  style="background: #28c76f !important; background-color: white !important; border: 1px solid #28c76f !important; color: white !important; padding: 4px 12px !important; border-radius: 5px !important; font-weight: 300 !important;"
                  class="p-auto float-right"
                  v-if="copiedLink"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                  />
                  <span class="align-middle ">link copied</span>
                </b-button>
                <b-button
                  variant="secondary"
                  style="background: white !important; background-color: white !important; border: 1px solid #CFD3E5 !important; color: #656B85 !important; padding: 4px 12px !important; border-radius: 5px !important; font-weight: 300 !important;"
                  class="p-auto float-right"
                  @click.prevent.stop="copyLink"
                  v-else
                >
                  <feather-icon
                    icon="CopyIcon"
                    class="mr-50"
                  />
                  <span class="align-middle ">Copy link</span>
                </b-button>
              </li>
            </ul>
          </b-nav-item-dropdown>
        </div>
      </div>
    </div>

    <div id="bottom-nav" />
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BAlert, BFormGroup, BFormInput, BFormCheckbox, BCardText, BButton, BAvatarGroup, BAvatar, BDropdownForm,
  BCard, BRow, BCol, BCardImgLazy, BImgLazy, VBTooltip, BDropdownItem, BDropdown, BBadge, BNavItemDropdown, BDropdownDivider,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { heightFade } from '@core/directives/animations'
import GroupSites from '@/views/extensions/acl/table/GroupSites.vue'
import MembersProject from '@/views/extensions/acl/table/sidebar/MembersProject.vue'
import SidebarOwnership from '@/views/extensions/acl/table/sidebar/SidebarOwnership.vue'
import SidebarProPages from '@/views/apps/sites/sites-edit/SidebarProPages.vue'
import RegisterSidebar from '@/views/pages/authentication/RegisterSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddSiteProject from '@/views/extensions/acl/table/sidebar/AddSiteProject.vue'
import ArchiveSite from '@/views/extensions/acl/table/sidebar/ArchiveSite.vue'
import InfoSubscription from '@/views/extensions/acl/table/sidebar/InfoSubscription.vue'
import Urlbox from 'urlbox'
import moment from 'moment'
import axios from '@axios'
import { db, auth, firebase, storage, dbErrors } from '@/firebase'
import { destroyed } from 'vue-echarts'

export default {
  name: 'TitlePage',
  components: {
    BLink,
    BAlert,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardText,
    BButton,
    BAvatarGroup,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BCardImgLazy,
    BImgLazy,
    BDropdownItem,
    BDropdown,
    BBadge,
    BNavItemDropdown,
    BDropdownDivider,
    BDropdownForm,

    // Navbar Components
    BNavbarNav,
    GroupSites,
    MembersProject,
    SidebarOwnership,
    SidebarProPages,
    RegisterSidebar,
    ToastificationContent,
    AddSiteProject,
    ArchiveSite,
    InfoSubscription,
    // Bookmarks,
    // Locale,
    // SearchBar,
    // DarkToggler,
    // CartDropdown,
    // NotificationDropdown,
    // UserDropdown,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'height-fade': heightFade,
    Ripple,
  },
  // props: {
  //   toggleVerticalMenuActive: {
  //     type: Function,
  //     default: () => {},
  //   },
  // },
  data() {
    return {
      validH: true,
      userData: '',
      checkedSharedWithMeSites: false,
      title: '',
      showOpen: false,
      showOpenMembersProject: false,
      showOpenSidebarProPages: false,
      showOpenSidebarMembers: false,
      showOpenRegisterSidebar: false,
      showOpenAddSiteProject: false,
      showOpenComingSoon: false,
      showOpenArchiveSite: false,
      idsite: {},
      proIcon: require('@/assets/images/icons/i-pro-badge.svg'),
      shareIcon: require('@/assets/images/icons/seocloud-share.svg'),
      copiedLink: false,
      copiedEverything: false,
      siteNotFound: false,
      isDevSite: false,
      isDropdown2Visible: false,
      isNotAlreadyOwner: true,
      cancelSub: false,
      statusLastCrawl: '',
      timerChangeInSite: null,
      showOpenSubscription: false,
      processingScript: false,

      // get site
      siteObj: '',
      sitesArray: [
        {
          imgSite: 'https://src.seocloud.ai/site-thumb.png', name: 'Mad House', domain: 'madhouse.com', currentErrors: '267', fixedIssues: '79', key: '805149744847148806725', alerts: '3', membership: 'free', ownedBy: 'other', label: '', color: '', id: '805149744847148806725',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite2.png?alt=media&token=f1814ebb-3b70-4be0-8a2f-eb464606379d', name: 'The House', domain: 'thehouse.com', currentErrors: '134', fixedIssues: '32', key: '183805949992209353412', alerts: '2', membership: 'pro', ownedBy: 'other', label: '', color: '', id: '183805949992209353412',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite3.png?alt=media&token=94157021-a3bb-476a-93e5-137faf8cf978', name: 'The best call', domain: 'thehousecall.com', currentErrors: '723', fixedIssues: '64', key: '436045364213400904226', alerts: '8', membership: 'free', ownedBy: 'other', label: '', color: '', id: '436045364213400904226',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite3.png?alt=media&token=94157021-a3bb-476a-93e5-137faf8cf978', name: 'The best call', domain: 'thehousecall.com', currentErrors: '723', fixedIssues: '64', key: '4360453642134009042262', alerts: '8', membership: 'free', ownedBy: 'other', label: '', color: '', id: '4360453642134009042262',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite4.png?alt=media&token=c0ad680f-b11d-4e63-b4e4-a9bccb94ccb3', name: 'Vuesax', domain: 'vuesax.com', currentErrors: '124', fixedIssues: '67', key: '963429738790920806012', alerts: '6', membership: 'free', ownedBy: 'other', label: '', color: '', id: '963429738790920806012',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite4.png?alt=media&token=c0ad680f-b11d-4e63-b4e4-a9bccb94ccb3', name: 'Vuesax', domain: 'vuesax.com', currentErrors: '124', fixedIssues: '67', key: '9634297387909208060122', alerts: '6', membership: 'Basic', ownedBy: '', label: '', color: '', id: '9634297387909208060122',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite5.png?alt=media&token=79676f96-08ef-49d5-8b6a-05a5a69e79d7', name: 'Focus', domain: 'focus.com', currentErrors: '364', fixedIssues: '45', key: '466475549830561301664', alerts: '2', membership: 'free', ownedBy: 'other', label: '', color: '', id: '466475549830561301664',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite5.png?alt=media&token=79676f96-08ef-49d5-8b6a-05a5a69e79d7', name: 'Focus', domain: 'focus.com', currentErrors: '364', fixedIssues: '45', key: '4664755498305613016642', alerts: '2', membership: 'Basic', ownedBy: 'other', label: '', color: '', id: '4664755498305613016642',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite6.png?alt=media&token=0adb58de-fa0c-4999-9c50-ebd9068789b9', name: 'Focus', domain: 'focus.com', currentErrors: '267', fixedIssues: '34', key: '4664755498305613016643', alerts: '7', membership: 'Pro', ownedBy: 'other', label: '', color: '', id: '4664755498305613016643',
        },
        // { imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite6.png?alt=media&token=0adb58de-fa0c-4999-9c50-ebd9068789b9', name: {name: 'Focus', id: projects.id, count: 6}, domain: 'focus.com', currentErrors: '267', fixedIssues: '34', key: '••••••••••••••••1046', alerts: '7', membership: 'pro', ownedBy: '' },
      ],
      siteObj2: '',
      sitesArray2: [
        {
          imgSite: 'https://src.seocloud.ai/site-thumb.png', name: 'Mad House', domain: 'madhouse.com', currentErrors: '267', fixedIssues: '79', key: '805149744847148806725', alerts: '3', membership: 'free', ownedBy: 'other', label: '', color: '', id: '805149744847148806725',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite2.png?alt=media&token=f1814ebb-3b70-4be0-8a2f-eb464606379d', name: 'The House', domain: 'thehouse.com', currentErrors: '134', fixedIssues: '32', key: '183805949992209353412', alerts: '2', membership: 'pro', ownedBy: 'other', label: '', color: '', id: '183805949992209353412',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite3.png?alt=media&token=94157021-a3bb-476a-93e5-137faf8cf978', name: 'The best call', domain: 'thehousecall.com', currentErrors: '723', fixedIssues: '64', key: '436045364213400904226', alerts: '8', membership: 'free', ownedBy: 'other', label: '', color: '', id: '436045364213400904226',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite3.png?alt=media&token=94157021-a3bb-476a-93e5-137faf8cf978', name: 'The best call', domain: 'thehousecall.com', currentErrors: '723', fixedIssues: '64', key: '4360453642134009042262', alerts: '8', membership: 'free', ownedBy: 'other', label: '', color: '', id: '4360453642134009042262',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite4.png?alt=media&token=c0ad680f-b11d-4e63-b4e4-a9bccb94ccb3', name: 'Vuesax', domain: 'vuesax.com', currentErrors: '124', fixedIssues: '67', key: '963429738790920806012', alerts: '6', membership: 'free', ownedBy: 'other', label: '', color: '', id: '963429738790920806012',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite4.png?alt=media&token=c0ad680f-b11d-4e63-b4e4-a9bccb94ccb3', name: 'Vuesax', domain: 'vuesax.com', currentErrors: '124', fixedIssues: '67', key: '9634297387909208060122', alerts: '6', membership: 'Basic', ownedBy: '', label: '', color: '', id: '9634297387909208060122',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite5.png?alt=media&token=79676f96-08ef-49d5-8b6a-05a5a69e79d7', name: 'Focus', domain: 'focus.com', currentErrors: '364', fixedIssues: '45', key: '466475549830561301664', alerts: '2', membership: 'free', ownedBy: 'other', label: '', color: '', id: '466475549830561301664',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite5.png?alt=media&token=79676f96-08ef-49d5-8b6a-05a5a69e79d7', name: 'Focus', domain: 'focus.com', currentErrors: '364', fixedIssues: '45', key: '4664755498305613016642', alerts: '2', membership: 'Basic', ownedBy: 'other', label: '', color: '', id: '4664755498305613016642',
        },
        {
          imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite6.png?alt=media&token=0adb58de-fa0c-4999-9c50-ebd9068789b9', name: 'Focus', domain: 'focus.com', currentErrors: '267', fixedIssues: '34', key: '4664755498305613016643', alerts: '7', membership: 'Pro', ownedBy: 'other', label: '', color: '', id: '4664755498305613016643',
        },
        // { imgSite: 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/sitesImgs%2Fsite6.png?alt=media&token=0adb58de-fa0c-4999-9c50-ebd9068789b9', name: {name: 'Focus', id: projects.id, count: 6}, domain: 'focus.com', currentErrors: '267', fixedIssues: '34', key: '••••••••••••••••1046', alerts: '7', membership: 'pro', ownedBy: '' },
      ],
      invitationsProject: 0,
      arrayInvitations: [],
      titleAtomic: '',
      owner: '',
      colorProject: '',
      actionImg: false,
      actionText: false,
      listPagesSidebars: [],
      urlActual: '',
      urlActualQuery: '',
      urlSection: null,
      urlContent: null,
      newSiteRegister: localStorage.getItem('addSite'),
      planLicensed: ''
      // invockeCharge: false,
    }
  },
  watch: {
    checkedSharedWithMeSites(val) {
      // this.shared = val
      localStorage.setItem('filtShared', !!(val === true || val === 'true'))
      // console.log(val)
      // console.log(val,this.shared)
    },
    showOpenSidebarProPages(val) {
      // console.log(val)
      // this.showOpenSidebarProPages = true
      const open = localStorage.getItem('viewSPro')
      if(open !== undefined && open === 'false') {
        localStorage.setItem('viewSPro', val)
        this.showOpenSidebarProPages = 'false'
      }
    },
    showOpenSidebarMembers(val) {
      const open = localStorage.getItem('viewSMem')
      if (open !== undefined && open === 'false') {
        localStorage.setItem('viewSMem', val)
        this.showOpenSidebarMembers = 'false'
      }
    },
    titleAtomic(val) {
      // console.log(val)
      this.chargeSite()
    },
    siteObj(val) {
      // console.log(val.length)
      if(val && val.length >= 1) {
        if(this.newSiteRegister) {
          setTimeout(() => {
            this.showOpenAddSiteProject = true
            localStorage.removeItem('addSite')
            this.newSiteRegister = null
          }, 1500)
        }
      }
    }
  },
  created() {
    this.title = ''
    this.idsite = {}
    this.siteObj2 = ''
    this.showOpenMembersProject = false
    this.showOpenRegisterSidebar = false
    this.copiedLink = false
    this.copiedEverything = false
    this.siteNotFound = false
    this.showOpenComingSoon = false
    this.showOpenArchiveSite = false
    this.isDevSite = false
    this.isNotAlreadyOwner = true
    this.cancelSub = false
    this.processingScript = false

    var urlWindow = window.location.href
    var urlWindowTransform = new URL(urlWindow)
    var urlWindowDomain = urlWindowTransform.hostname
    // console.log(urlWindowDomain)
    if(urlWindowDomain === 'app.seocloud.ai' || urlWindowDomain === 'localhosts') {
      // this.isDevSite = true
    }

    // const user = auth.currentUser
    // if(user) {
    //   db.collection('Users').doc(user.uid).get()
    //   .then(userDoc => {
    //     this.userData = userDoc.data()
    //   })
    // }
  },
  setup() {
    return {
      avatarText,
    }
  },
  methods: {
    getSidebarInfoSubscription() {
      // history.pushState(null, "", `?lic=upd`)
      // localStorage.setItem('licenseUpd', true)
      this.showOpenSubscription = true
    },
    logIdSite(val) {
      this.showOpen = true
      // const user = auth.currentUser
    },
    logIdMembersProject(label, own) {
      // 
      if(this.$route.name === 'view-errors') {
        this.$router.push({ name: 'project-sites', params: {id: this.$route.params.project, name: label, own: this.idsite.permission ? this.idsite.permission : ''} })
      }
    },
    getMembership() {
      // { name: 'memberships', params: {idsite: props.row} }
      // console.log('first')
      // this.siteObj2 = ''
      // this.siteObj2 = this.sitesArray2.filter(ele => ele.id === this.$route.params.id)
      if(this.siteObj2 && this.siteObj2.length >= 1) {
        // console.log(this.siteObj2)
        if(this.$route.params.project) {
          this.siteObj2[0].projectid = this.$route.params.project
        } else if(this.$route.params.id) {
          this.siteObj2[0].projectid = this.$route.params.id
        }
        this.$router.push({ name: 'memberships', params: { idsite: this.siteObj2[0] } })
      } else {
        this.$router.push({ name: 'memberships' })
      }
    },
    chargeSite() {
      // this.invockeCharge = true
      // console.log('first')
      this.urlActual = window.location
      var memberPerm = 'view'
      const user = auth.currentUser
      if(this.$route.name !== 'view-errors') {
        this.isNotAlreadyOwner = true
        this.cancelSub = false
        this.planLicensed = ''
        this.siteObj = []
      }
      
      if(this.$route.name === 'shared-site') {
        // console.log(this.$route.query.site)
        // this.idsite = {}
        this.userData = {}
        this.userData.uid = user ? user.uid : 'GJJIqP4PhihGS1CL4kcCAhH1ySr2'
        this.siteObj = []
        db.collection('Sites').where('WebsiteURL', '==', this.$route.query.site).limit(1).get()
        .then(siteQueryWebsite => {
          if(siteQueryWebsite.size) {
            siteQueryWebsite.forEach(eleWebsiteSite => {
              var projectWebsite = eleWebsiteSite.data().projects[0]
              db.collection('Sites').doc(eleWebsiteSite.id).get()
              .then(site => {
                if(site.exists) {
                  // console.log(site.data().owner,user.uid)
                  // console.log(site.data().thumbnail)
                  // this.siteObj = []
                  var projectID = projectWebsite
                  this.idsite.domain = site.data().WebsiteURL
                  this.idsite.name = site.data().name ? site.data().name : site.data().homepage
                  this.idsite.idsite = projectID
                  this.idsite.imgSite = site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png'
                  this.idsite.permission = site.data().owner && site.data().owner.uid === this.userData.uid ? 'owner' : 'edit'
                  // console.log(this.idsite.permission)
                  var siteHomepage = site.data().homepage
                  // var SiteID = site.id
                  var domainPrefix = site.data().domainPrefix ? site.data().domainPrefix : ''
                  // var siteWebsiteURL = site.data().WebsiteURL
                  if(this.siteObj.length === 0) {
                    if(site.data().project) {
                      // db.collection('Projects').doc(site.data().project).get()
                      // .then(projects => {
                      //   if(projects.exists) {
                          // db.collection('Projects').doc(site.data().project).collection('members').where('uid', '!=', 'resume').get()
                          // .then(invitationsProject => {
                          //   var arrayInvitations = []
                          //   if(invitationsProject.size >= 1) {
                          //     invitationsProject.forEach(inv => {
                          //       arrayInvitations.push(inv.data())
                          //     })
                          //   }
                          //   var isMember = arrayInvitations.filter(ele => ele.uid === site.data().createdBy || (site.data().owned && ele.uid === site.data().owned.uid) || projects.data().owned.uid === site.data().createdBy || (site.data().owned && projects.data().owned.uid === site.data().owned.uid))
                            
                            this.siteObj.push({
                              imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                              name: site.data().name ? site.data().name : site.data().homepage,
                              domain: site.data().homepage,
                              currentErrors: '267',
                              fixedIssues: '79',
                              key: site.id,
                              alerts: '3',
                              membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
                              ownedBy: site.data().owner && site.data().owner.uid === this.userData.uid ? site.data().owner : {},
                              owner: 'me',
                              label: '',
                              color: '',
                              id: site.id,
                              projectid: projectWebsite
                            })
                          // })
                        // }
                      // })
                    } else {
                      this.siteObj.push({
                        imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                        name: site.data().name ? site.data().name : site.data().homepage,
                        domain: site.data().homepage,
                        currentErrors: '267',
                        fixedIssues: '79',
                        key: site.id,
                        alerts: '3',
                        membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
                        ownedBy: site.data().owner && site.data().owner.uid === this.userData.uid ? site.data().owner : {},
                        owner: 'me',
                        label: '',
                        color: '',
                        id: site.id,
                        projectid: projectWebsite
                      })
                    }
                      // this.crawlRunning = false
                      // this.crawlID = site.data().mostRecentCrawl
                      // this.crawls.estatus === 'running' ? this.crawls = {} : ''
                      // this.crawls.id = site.data().mostRecentCrawl
                      // this.crawls.estatus = site.data().mostRecentCrawlStatus
                      // this.crawls.availableURL = site.data().totalPages
                      // this.crawls.date = moment(site.data().mostRecentCrawlDate).utcOffset("-06:00").format("Do MMMM YYYY")
                      var arrayPages = site.data().pages && site.data().pages.length >= 1 ? site.data().pages : []
                      // var totalRequest = arrayPages.filter(ele => ele.status !== null)
                      // this.crawls.requests = totalRequest.length
                      var inlinks = site.data().inlinks && site.data().inlinks.length ? site.data().inlinks : []
                      var proPages = site.data().proPages && site.data().proPages.length ? site.data().proPages : []
                      var docsExternals = site.data().externalLinks && site.data().externalLinks.length >= 1 ? site.data().externalLinks : []
                      var docsObjErrors = site.data().errorsPages && site.data().errorsPages.length >= 1 ? site.data().errorsPages : []
                      var arrayProPages = []
                      var array200Pages = []
                      var array200XML = []
                      var array302Pages = []
                      var array301Pages = []
                      var array404Pages = []
                      var arrayTimeoutPages = []
                      var arrayOtherPages = []
                      var idListPage = 0
                      this.listPagesSidebars = []
                      var filterInlinksHome = []
                      arrayPages.forEach(itemPage => {
                        var URLactual = new URL(itemPage.url)
                        if((itemPage.url.includes('.xml') && itemPage.status === 200) || (itemPage.status && !itemPage.url.includes('.xml'))) {
                          var filterPage = this.listPagesSidebars.filter(ele => ele.cUrl === itemPage.url)
                          var filterHomePage = this.listPagesSidebars.filter(ele => ele.id === 0)
                          // var idListPage = incrementalIdPages
                          var filterInlinksPage = inlinks.filter(item => item.link === itemPage.url)
                          var filterPro = proPages.filter(ele => ele === itemPage.url)
                          var filterExternals = docsExternals.filter(item => item.source === itemPage.url)
                          var filterObjErrors = docsObjErrors.filter(errObj => errObj.url === itemPage.url)
                          
                          if(URLactual.pathname === '/') {
                            var urlcrawl = new URL(siteHomepage)
                            var urlProtocol = urlcrawl.protocol
                            var URLdomainFound = urlcrawl.host
                            var URLwwwFound = ''
                            var URLwithoutWWWFound = ''
                            if(URLdomainFound.includes('www.')) {
                              URLwwwFound = urlProtocol + '//' + URLdomainFound
                              URLwithoutWWWFound = urlProtocol + '//' + URLdomainFound.slice(4)
                            } else {
                              URLwwwFound = urlProtocol + '//' + 'www.'+URLdomainFound
                              URLwithoutWWWFound = urlProtocol + '//' + URLdomainFound
                            }
                            filterInlinksHome = inlinks.filter(item => item.link === siteHomepage || item.link === siteHomepage+ '/' || item.link === URLwwwFound || item.link === URLwithoutWWWFound || item.link === URLwwwFound + '/' || item.link === URLwithoutWWWFound + '/')
                          }

                          if(URLactual.pathname === '/' && filterHomePage.length === 0) {
                          // if(URLactual.pathname === '/') {
                            // var filterInlinksPageHome = inlinks.filter(item => item.link === itemPage.url || item.link === itemPage.url + '/')
                            this.listPagesSidebars.unshift({
                              id: 0,
                              selected: false,
                              page: 'home',
                              title: itemPage.title ? itemPage.title : 'home',
                              cTitle: itemPage.title ? itemPage.title : '',
                              pro: true,
                              url: 'Homepage',
                              // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                              inlinks: filterInlinksHome.length,
                              inlinksList: filterInlinksHome,
                              errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                              name: 'a',
                              cUrl: itemPage.url,
                              status: itemPage.status,
                              redirectTo: itemPage.redirectTo
                              // exist: true,
                            })
                          } else if(filterPage.length === 0) {
                            if(URLactual.pathname !== '/') {
                              idListPage += 1
                              if(filterPro.length >= 1) {
                                var filterP = arrayProPages.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  arrayProPages.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: true,
                                    url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              } else if(itemPage.status === 200) {
                                if(itemPage.url.includes('.xml')) {
                                  var filterP = array200XML.filter(ele => ele.cUrl === itemPage.url)
                                  if(filterP.length) {
                                    idListPage -= 1
                                  } else {
                                    array200XML.push({
                                      id: idListPage,
                                      selected: false,
                                      page: URLactual.pathname,
                                      title: itemPage.title ? itemPage.title : URLactual.pathname,
                                      cTitle: itemPage.title ? itemPage.title : '',
                                      pro: itemPage.pro ? true : false,
                                      url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                      // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                      inlinks: filterInlinksPage.length,
                                      inlinksList: filterInlinksPage,
                                      errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                      name: URLactual.pathname,
                                      cUrl: itemPage.url,
                                      status: itemPage.status,
                                      redirectTo: itemPage.redirectTo
                                      // exist: true,
                                    })
                                  }
                                } else {
                                  var filterP = array200Pages.filter(ele => ele.cUrl === itemPage.url)
                                  if(filterP.length) {
                                    idListPage -= 1
                                  } else {
                                    array200Pages.push({
                                      id: idListPage,
                                      selected: false,
                                      page: URLactual.pathname,
                                      title: itemPage.title ? itemPage.title : URLactual.pathname,
                                      cTitle: itemPage.title ? itemPage.title : '',
                                      pro: itemPage.pro ? true : false,
                                      url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                      // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                      inlinks: filterInlinksPage.length,
                                      inlinksList: filterInlinksPage,
                                      errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                      name: URLactual.pathname,
                                      cUrl: itemPage.url,
                                      status: itemPage.status,
                                      redirectTo: itemPage.redirectTo
                                      // exist: true,
                                    })
                                  }
                                }
                              } else if(itemPage.status === 302) {
                                var filterP = array302Pages.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  array302Pages.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: itemPage.pro ? true : false,
                                    url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              } else if(itemPage.status === 301) {
                                var filterP = array301Pages.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  array301Pages.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: itemPage.pro ? true : false,
                                    url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              } else if(itemPage.status === 404) {
                                var filterP = array404Pages.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  array404Pages.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: itemPage.pro ? true : false,
                                    url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              } else if(itemPage.status === 'timeout') {
                                var filterP = arrayTimeoutPages.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  arrayTimeoutPages.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: itemPage.pro ? true : false,
                                    url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              } else {
                                var filterP = arrayOtherPages.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  arrayOtherPages.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: itemPage.pro ? true : false,
                                    url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              }
                            }
                          } else {
                            if(filterPro.length >= 1) {
                              filterPage.forEach(elePro => {
                                elePro.pro = true
                                elePro.status = itemPage.status
                                elePro.errors = filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0)
                              })
                            } else if(URLactual.pathname === '/') {
                              filterPage.forEach(elePro => {
                                // elePro.pro = true
                                elePro.status = itemPage.status
                                elePro.errors = filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0)
                              })
                            }
                          }
                        }
                      })

                      var filterHomePage = this.listPagesSidebars.filter(ele => ele.id === 0)
                      if(filterHomePage.length === 0) {
                        this.listPagesSidebars.unshift({
                          id: 0,
                          selected: false,
                          page: 'home',
                          title: 'home',
                          pro: true,
                          url: 'Homepage',
                          inlinks: filterInlinksHome.length,
                          inlinksList: filterInlinksHome,
                          errors: 0,
                          name: 'a',
                          cUrl: siteHomepage,
                          status: 'timeout'
                          // exist: true,
                        })
                      }

                      var filterStatusHome = arrayPages.filter(ele => ele.url === siteHomepage && ele.status !== null)
                      if(filterHomePage.length && filterStatusHome.length) {
                        // console.log(filterStatusHome)
                        if(filterStatusHome.length === 1) {
                          this.listPagesSidebars[0].status = filterStatusHome[0].status
                        } else {
                          var status200 = filterStatusHome.filter(ele => ele.status === 200)
                          var status302 = filterStatusHome.filter(ele => ele.status === 302)
                          var status301 = filterStatusHome.filter(ele => ele.status === 301)
                          var status404 = filterStatusHome.filter(ele => ele.status === 404)
                          if(status200.length) {
                            this.listPagesSidebars[0].status = status200[0].status
                          } else if(status302.length) {
                            this.listPagesSidebars[0].status = status302[0].status
                          } else if(status301.length) {
                            this.listPagesSidebars[0].status = status301[0].status
                          } else if(status404.length) {
                            this.listPagesSidebars[0].status = status404[0].status
                          } else {
                            this.listPagesSidebars[0].status = filterStatusHome[0].status
                          }
                        }
                      }

                      var condicionParaOrdenar = (pageA, pageB) => {
                        return pageB.inlinks - pageA.inlinks;
                      }
                      arrayProPages.sort(condicionParaOrdenar)
                      array200Pages.sort(condicionParaOrdenar)
                      array200XML.sort(condicionParaOrdenar)
                      array302Pages.sort(condicionParaOrdenar)
                      array301Pages.sort(condicionParaOrdenar)
                      array404Pages.sort(condicionParaOrdenar)
                      arrayTimeoutPages.sort(condicionParaOrdenar)
                      arrayOtherPages.sort(condicionParaOrdenar)
                      var incrementalIdPages = 0

                      // array200Pages.push({
                      //   pro: false,
                      //   cUrl: 'https://viventi.com.gt/test2',
                      //   status: 200,
                      //   id: 1,
                      //   selected: false,
                      //   page: 'test',
                      //   title: 'test',
                      //   cTitle: 'test',
                      //   url: '/test2',
                      //   inlinks: 0,
                      //   inlinksList: [],
                      //   errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                      //   name: 'test',
                      //   redirectTo: null
                      // })

                      arrayProPages.forEach(ele => {
                        incrementalIdPages += 1
                        this.listPagesSidebars.push({
                          id: incrementalIdPages,
                          selected: ele.selected,
                          page: ele.page,
                          title: ele.title,
                          cTitle: ele.cTitle,
                          pro: ele.pro,
                          url: ele.url,
                          inlinks: ele.inlinks,
                          inlinksList: ele.inlinksList,
                          errors: ele.errors,
                          name: ele.name,
                          cUrl: ele.cUrl,
                          status: ele.status,
                          redirectTo: ele.redirectTo
                          // exist: true,
                        })
                      })
                      
                      array200Pages.forEach(ele => {
                        incrementalIdPages += 1
                        this.listPagesSidebars.push({
                          id: incrementalIdPages,
                          selected: ele.selected,
                          page: ele.page,
                          title: ele.title,
                          cTitle: ele.cTitle,
                          pro: ele.pro,
                          url: ele.url,
                          inlinks: ele.inlinks,
                          inlinksList: ele.inlinksList,
                          errors: ele.errors,
                          name: ele.name,
                          cUrl: ele.cUrl,
                          status: ele.status,
                          redirectTo: ele.redirectTo
                          // exist: true,
                        })
                      })

                      // array302Pages.forEach(ele => {
                      //   incrementalIdPages += 1
                      //   this.listPagesSidebars.push({
                      //     id: incrementalIdPages,
                      //     selected: ele.selected,
                      //     page: ele.page,
                      //     title: ele.title,
                      //     cTitle: ele.cTitle,
                      //     pro: ele.pro,
                      //     url: ele.url,
                      //     inlinks: ele.inlinks,
                      //     inlinksList: ele.inlinksList,
                      //     errors: ele.errors,
                      //     name: ele.name,
                      //     cUrl: ele.cUrl,
                      //     status: ele.status,
                      //     redirectTo: ele.redirectTo
                      //     // exist: true,
                      //   })
                      // })

                      // array200XML.forEach(ele => {
                      //   incrementalIdPages += 1
                      //   this.listPagesSidebars.push({
                      //     id: incrementalIdPages,
                      //     selected: ele.selected,
                      //     page: ele.page,
                      //     title: ele.title,
                      //     cTitle: ele.cTitle,
                      //     pro: ele.pro,
                      //     url: ele.url,
                      //     inlinks: ele.inlinks,
                      //     inlinksList: ele.inlinksList,
                      //     errors: ele.errors,
                      //     name: ele.name,
                      //     cUrl: ele.cUrl,
                      //     status: ele.status,
                      //     redirectTo: ele.redirectTo
                      //     // exist: true,
                      //   })
                      // })

                      // array301Pages.forEach(ele => {
                      //   incrementalIdPages += 1
                      //   this.listPagesSidebars.push({
                      //     id: incrementalIdPages,
                      //     selected: ele.selected,
                      //     page: ele.page,
                      //     title: ele.title,
                      //     cTitle: ele.cTitle,
                      //     pro: ele.pro,
                      //     url: ele.url,
                      //     inlinks: ele.inlinks,
                      //     inlinksList: ele.inlinksList,
                      //     errors: ele.errors,
                      //     name: ele.name,
                      //     cUrl: ele.cUrl,
                      //     status: ele.status,
                      //     redirectTo: ele.redirectTo
                      //     // exist: true,
                      //   })
                      // })

                      // array404Pages.forEach(ele => {
                      //   incrementalIdPages += 1
                      //   this.listPagesSidebars.push({
                      //     id: incrementalIdPages,
                      //     selected: ele.selected,
                      //     page: ele.page,
                      //     title: ele.title,
                      //     cTitle: ele.cTitle,
                      //     pro: ele.pro,
                      //     url: ele.url,
                      //     inlinks: ele.inlinks,
                      //     inlinksList: ele.inlinksList,
                      //     errors: ele.errors,
                      //     name: ele.name,
                      //     cUrl: ele.cUrl,
                      //     status: ele.status,
                      //     redirectTo: ele.redirectTo
                      //     // exist: true,
                      //   })
                      // })

                      // arrayTimeoutPages.forEach(ele => {
                      //   incrementalIdPages += 1
                      //   this.listPagesSidebars.push({
                      //     id: incrementalIdPages,
                      //     selected: ele.selected,
                      //     page: ele.page,
                      //     title: ele.title,
                      //     cTitle: ele.cTitle,
                      //     pro: ele.pro,
                      //     url: ele.url,
                      //     inlinks: ele.inlinks,
                      //     inlinksList: ele.inlinksList,
                      //     errors: ele.errors,
                      //     name: ele.name,
                      //     cUrl: ele.cUrl,
                      //     status: ele.status,
                      //     redirectTo: ele.redirectTo
                      //     // exist: true,
                      //   })
                      // })

                      // arrayOtherPages.forEach(ele => {
                      //   incrementalIdPages += 1
                      //   this.listPagesSidebars.push({
                      //     id: incrementalIdPages,
                      //     selected: ele.selected,
                      //     page: ele.page,
                      //     title: ele.title,
                      //     cTitle: ele.cTitle,
                      //     pro: ele.pro,
                      //     url: ele.url,
                      //     inlinks: ele.inlinks,
                      //     inlinksList: ele.inlinksList,
                      //     errors: ele.errors,
                      //     name: ele.name,
                      //     cUrl: ele.cUrl,
                      //     status: ele.status,
                      //     redirectTo: ele.redirectTo
                      //     // exist: true,
                      //   })
                      // })
                    
                  } else {
                    this.siteObj[0].ownedBy = site.data().owner && site.data().owner.uid === this.userData.uid ? site.data().owner : {}
                    this.siteObj[0].imgSite = site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png'
                  }
                }
              })

              if(this.$route.name === 'project-sites' || this.$route.name === 'view-errors' || this.$route.name === 'shared-site') {
                // console.log(this.$route.params.id)
                var projectID = projectWebsite
                db.collection('Projects').doc(projectID).get()
                .then(projects => {
                  // console.log(projects.exists)
                  if(projects.exists) {
                    this.owner = 'me'
                    this.colorProject = projects.data().color ? projects.data().color : ''
                    this.invitationsProject = 0
                    this.invitationsProject = 0
                    this.title = projects.data().name ? projects.data().name : ''
                    this.idsite.label = projects.data().name ? projects.data().name : ''
                    this.idsite.color = projects.data().color ? projects.data().color : ''

                    // array of members
                    this.arrayInvitations = []
                  } else {
                    this.idsite.idsite = projectID ? projectID : ''
                    this.idsite.label = 'My sites'
                    this.idsite.color = '#181E38'
                    this.idsite.permission = 'owner'
                  }
                })
              }
            })
          } else {
            if(this.$route.query.crawl && this.$route.query.site) {
              if(this.$route.query.crawl === 'start') {
                console.log('create site')
                var urlHost = new URL('https://' + this.$route.query.site)
                var URLdomain = this.$route.query.site
                var urlBodyx = 'https://' + this.$route.query.site
                var urlPrefix = this.$route.query.site.includes('www') ? 'www' : 'non-www'

                db.collection('Sites').add({
                  'SiteID': '',
                  'name': urlHost.host,
                  'n': urlHost.host ? urlHost.host.toLowerCase() : '',
                  'WebsiteURL': URLdomain,
                  'homepage': urlBodyx,
                  thumbnail: '',
                  'Blockedbyrobot': 'no',
                  // 'pagesFound': '',
                  'proPages': [],
                  'asignatedProPages': false,
                  'mostRecentCrawl': '',
                  'mostRecentCrawlStatus': '',
                  'totalErrors': 0,
                  'errorsCategory': {},
                  'claimed': false,
                  'owner': '',
                  'plan': '',
                  'paidRecurrence': '',
                  'totalPages': 0,
                  'createdBy': this.userData.uid,
                  'created': Date.now(),
                  'registrationDay': moment().format('dddd'),

                  'owners': [],
                  'limitCrawl': 600,
                  'prot': 'https:',
                  'domainPrefix': urlPrefix,
                  'stringSearch': ['PHP ERROR','Lorem','Ipsum','Consectetur'],
                  'totalSuccessCrawl': 0,
                  'externalLinks': [],
                  'pageSizes': [],
                  'projects': ['guess'],
                  'plus1k': false,
                  'teamMembers': [],
                  'errorsPages': [],
                  'suspiciousText': ['viagra'],
                  'n_guess_crawls': 0,
                  'n_log_crawls': 0
                })
                .then(docRefSite => {
                  this.takeScreenshotNow(urlBodyx)

                  // update Sites id
                  db.collection('Sites').doc(docRefSite.id).update({
                    SiteID: docRefSite.id,
                    teamMembers: firebase.firestore.FieldValue.arrayUnion(this.userData.uid),
                    n_guess_crawls: 1
                  })
                  .catch(e => console.log('Error update ref site: ', e))

                  let dataSrc = JSON.stringify({
                    "website": urlBodyx
                  });
            
                  let configSrc = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: 'https://sslcheckerapi-h4klmyt43a-uc.a.run.app/screenshots/register',
                    headers: { 
                      'Content-Type': 'application/json'
                    },
                    data : dataSrc
                  };
            
                  this.axios(configSrc)
                  .then((response) => {
                    console.log('register screenshots: ', response)
                  })
                  .catch((error) => {
                    console.error('Error register screenshot: ', error)
                  });

                  db.collection('Users').doc(this.userData.uid).get()
                  .then(docUser => {
                    var objnewSiteMail = {
                      nameUser: docUser.data().nombre ? docUser.data().nombre : docUser.data().email,
                      email: docUser.data().email,
                      existUser: true,
                      urlSite: URLdomain,
                      idsite: docRefSite.id,
                      idproject: 'guess'
                    }

                    // dbErrors.collection('fixes_cache').add({
                    //   SiteID: docRefSite.id,
                    //   owner: docUser.id,
                    //   ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                    //   changes: [],
                    //   updated: Date.now()
                    // })

                    // update seocloud-dcdfb
                    var confignewSiteMail = {
                      method: 'POST',
                      url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/newSiteMail',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      data: JSON.stringify(objnewSiteMail),
                    }

                    this.axios(confignewSiteMail)
                    .then(() => {
                      console.log('email sended')
                    })
                    .catch(error => {
                      console.log(error)
                    })
                  })

                  db.collection('Sites_cache').doc(docRefSite.id).set({
                    'SiteID': docRefSite.id,
                    'name': urlHost.host,
                    'n': urlHost.host ? urlHost.host.toLowerCase() : '',
                    'WebsiteURL': URLdomain,
                    'homepage': urlBodyx,
                    thumbnail: '',
                    'Blockedbyrobot': 'no',
                    // 'pagesFound': '',
                    'proPages': [],
                    inlinks: [],
                    'asignatedProPages': false,
                    'mostRecentCrawl': '',
                    'mostRecentCrawlStatus': '',
                    'totalErrors': 0,
                    'errorsCategory': {},
                    'claimed': false,
                    'owner': '',
                    'plan': '',
                    'paidRecurrence': '',
                    'totalPages': 0,
                    'createdBy': this.userData.uid,
                    'projects': ['guess'],
                    'plus1k': false
                  })
                  .catch(e => console.log('Error add site cache: ', e))
                  
                  // add site to project
                  db.collection('Projects').doc('guess').collection('sites').doc(docRefSite.id).set({
                    'SiteID': docRefSite.id,
                    'WebsiteURL': URLdomain,
                    'homepage': urlBodyx,
                    'name': urlHost.host,
                    'n': urlHost.host ? urlHost.host.toLowerCase() : '',
                    thumbnail: '',
                    'totalErrors': 0,
                    'plan': '',
                    'paidRecurrence': '',
                    'claimed': false,
                    'owner': '',
                    'status': 'active',
                    // projectId: 'guess',
                  })
                  .catch(error => {
                    console.error(error)
                  })

                  if(docRefSite.id) {
                    const urlbox = Urlbox('iWVAo1U5wUbLhzWJ', 'c3e32765daba406cb27d1ad22ed93b6d');
                          
                    // Set your options
                    const options = {
                      url: urlBodyx,
                      // thumb_width: 1440,
                      width: 1440,
                      height: 840,
                      format: "jpeg",
                      quality: 70,
                      delay: 500
                    };
                    
                    const urlImgUrl = urlbox.buildUrl(options)

                    var obj = {
                      url: urlImgUrl
                    }
                    var config = {
                      method: 'POST',
                      url: 'https://luguzxy83e.execute-api.us-east-2.amazonaws.com/prod/imgseocloud',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      data: JSON.stringify(obj),
                    }

                    this.axios(config)
                    .then(async response => {
                      // const refImg = await storage.ref().child('sitesImgs').child(`${Date.now()}siteImg`)
                      // const upl = await refImg.putString(response.data, 'data_url')
                      // const urlDescarga = await refImg.getDownloadURL()
                      // let query = this.$route.query
                      // this.$router.replace({query: {...query, imgSite: urlDescarga}})
                      // // update thumbnail
                      // db.collection('Sites').doc(docRefSite.id).update({
                      //   thumbnail: urlDescarga
                      // })
                      // .then(() => {
                      //   localStorage.setItem('changeNewThumb', true)
                      // })

                      // db.collection('Sites_cache').doc(docRefSite.id).update({
                      //   thumbnail: urlDescarga
                      // })

                      // db.collection('Projects').doc('guess').collection('sites').doc(docRefSite.id).update({
                      //   thumbnail: urlDescarga
                      // })

                      // if(this.siteObj.length >= 1) {
                      //   this.siteObj[0].imgSite = urlDescarga
                      // }

                      try {
                        const nameFileSave = `${Date.now()}siteImg`;
                        const result = await axios.post(
                          'https://us-central1-seocloud-dcdfb.cloudfunctions.net/writeCrawl/save-img/src-seo', // URL de tu función
                          {
                            fileData: response.data, // Base64 del archivo
                            fileName: `${nameFileSave}`, // Ruta del archivo en el bucket
                            fileBucket: 'siteImg'
                          }
                        );

                        // console.log('Archivo subido y público:', result.data.url)

                        if(result.data.url) {
                          const urlDescarga = result.data.url
                          let query = this.$route.query
                          this.$router.replace({query: {...query, imgSite: urlDescarga}})

                          // update thumbnail
                          db.collection('Sites').doc(docRefSite.id).update({
                            thumbnail: urlDescarga,
                            thumb_update: Date.now()
                          })

                          db.collection('Sites_cache').doc(docRefSite.id).update({
                            thumbnail: urlDescarga
                          })

                          db.collection('Projects').doc('guess').collection('sites').doc(docRefSite.id).update({
                            thumbnail: urlDescarga
                          })

                          if(this.siteObj.length >= 1) {
                            this.siteObj[0].imgSite = urlDescarga
                          }
                        }
                      } catch (error) {
                        console.error('Error al subir el archivo:', error);
                      }
                    })
                  }
                  
                  if(docRefSite.id) {
                    var body = {
                      url: '',
                      type: 'full',
                      forceNotFound: 'yes',
                      pageSize: 999999999,
                      id: 'seo-shops-full-' + Date.now(),
                      takeScreenshots: 'no',
                      source: 'seo-cloud-full-' + Date.now(),
                      triggeredBy: 'test triggered',
                      screenshotType: 'test',
                      screenshotComment: '',
                      stringSearch: ["PHP ERROR",'Lorem','Ipsum'],
                      ignoreIndexNoFollow: "yes"
                    }
                    var getUrl = new URL(urlBodyx);
                    var hostUrl = getUrl.host;
                    var httpsString = getUrl.protocol + '//';
                    var urlConcat = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                    var urlBody = httpsString + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                    var ultimoCaracterUrl = urlConcat.charAt(urlConcat.length - 1);
                    if(ultimoCaracterUrl != "/") {
                      urlConcat += "/";
                    }

                    body.id = 'seo-shops-full-' + Date.now()
                    body.source = 'seo-shops-full-' + Date.now()
                    body.url = getUrl.protocol + "//" + hostUrl

                    if(getUrl.pathname === "/") {
                      body.takeScreenshots = 'no'
                      body.screenshotComment = ""
                    }

                    db.collection('Sites').doc(docRefSite.id).get()
                    .then(eleSite => {
                      // exist site
                      var countSites = 0
                      var pageSizes = eleSite.data().pageSizes && eleSite.data().pageSizes.length ? eleSite.data().pageSizes : []
                      var pagesSite = eleSite.data().pages && eleSite.data().pages.length ? eleSite.data().pages : []
                      var filterInternals = pagesSite.filter(ele => ele.isSitemap === true)
                      var filterNoInternals = pagesSite.filter(ele => ele.isSitemap === false)
                      var filterProPages = filterNoInternals.filter(ele => ele.pro === true)
                      var filterNoProPages = filterNoInternals.filter(ele => ele.pro === false)
                      var filterInlinks = filterNoProPages

                      var condicionParaOrdenar = (pageA, pageB) => {
                        return pageB.inlinks.length - pageA.inlinks.length;
                      }
                      filterInlinks.sort(condicionParaOrdenar)

                      var arrayFoundPages = []
                      var arrayProPages = []
                      filterProPages.forEach(ele => {
                        var getUrl = new URL(ele.url)
                        if(getUrl.pathname !== '/') {
                          arrayProPages.push(ele.url)
                        }

                        arrayFoundPages.unshift(ele.url)
                      })
                      
                      db.collection('Crawls').where('WebsiteURL', '==', hostUrl).where('status', '==', 'running').get()
                      .then(queryCrawl => {
                        // only 1 loop
                        if(countSites === 0 && !queryCrawl.size) {
                          countSites += 1
                          var stringsArray = eleSite.data().stringSearch ? eleSite.data().stringSearch : []
                          var suspiciousTextArray = eleSite.data().suspiciousText ? eleSite.data().suspiciousText : []
                          var arrayStringsSuspicious = []
                          stringsArray.forEach(ele => {
                            arrayStringsSuspicious.push(ele)
                          })

                          suspiciousTextArray.forEach(ele => {
                            arrayStringsSuspicious.push(ele)
                          })

                          var getConfigCrawl = localStorage.getItem('configCrawl')
                          var limitCrawl = 100
                          var configCrawlType = 'HTML'
                          var configCrawlPages = this.userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? 'GUESS' : 'GUESS/FREE'
                          var setCrawl = false
                          if(getConfigCrawl) {
                            setCrawl = true
                            var parseConfig = JSON.parse(getConfigCrawl)
                            var logicTime = moment(parseConfig.date)
                            var actualTime = moment(new Date())
                            var diffSeconds = actualTime.diff(logicTime, 'seconds');
                            if(diffSeconds < 600) {
                              limitCrawl = parseConfig.pages
                              configCrawlType = parseConfig.crawl
                              configCrawlPages = parseConfig.pages === 25 ? (this.userData.uid === 'GJJIqP4PhihGS1CL4kcCAhH1ySr2' ? 'GUESS' : 'GUESS/FREE') : parseConfig.pages === 100 ? 'FREE' : parseConfig.pages === 600 ? 'LICENSE' : 'FREE'
                            }
                            var getConfigCrawl = localStorage.removeItem('configCrawl')
                          } else {
                            limitCrawl = 100
                          }
                          
                          var limitCrawlSetSite = eleSite.data().limitCrawl ? eleSite.data().limitCrawl : 0
                          if(limitCrawlSetSite) {
                            if((configCrawlPages === 'GUESS' || configCrawlPages === 'GUESS/FREE') && limitCrawlSetSite < 25) {
                              limitCrawl = limitCrawlSetSite
                            } else if(configCrawlPages === 'FREE' && limitCrawlSetSite < 100) {
                              limitCrawl = limitCrawlSetSite
                            } else if(configCrawlPages === 'LICENSE') {
                              limitCrawl = limitCrawlSetSite
                            }
                          }

                          // add Crawl
                          db.collection('Crawls').add({
                            'IDcrawl': body.id,
                            'dateStart': Date.now(),
                            'dateStartFormat': new Date(),
                            'dateEnd': null,
                            'crawlDuration': null,
                            'status': 'running',
                            'crawltype': 'full',
                            'ignoreindexnofollow': 'yes',
                            'Requests': 1,
                            'SiteID': eleSite.id,
                            'WebsiteURL': hostUrl,
                            'blockedByRobots': false,
                            'logicRequests': 1,
                            'proPages': eleSite.data().proPages.length ? eleSite.data().proPages : [urlBody],
                            'totalErrors': 0,
                            'limitCrawl': limitCrawl,
                            'stringSearch': arrayStringsSuspicious,
                            'founds': arrayFoundPages,
                            'requests': [],
                            'pages': [],
                            'inlinks': [],
                            'external': [],
                            'pageSizes': pageSizes,
                            'updated': Date.now(),
                            'plus1k': false,
                            'errorsPages': [],
                            'source': 'seo',
                            'configCrawlType': 'HTML',
                            'configCrawlPages': 'GUESS',
                            'errorsTypePage': [],
                            'timeoutPages': 0,
                            'errorsCategory': {}
                          })
                          .then((docRefCrawl) => {
                            var URLsfound = [
                              {
                                'id': null,
                                'title': null,
                                'runPosition': 1,
                                'lastRequest': null,
                                'pro': true,
                                'inlinks': [],
                                'status': null,
                                'redirectTo': null,
                                'blockedByRobots': false,
                                // 'totalErrors': 0,

                                'crawleable': true,
                                'crawled': false,
                                'crawlerInvoke': true,
                                'url': urlBody
                                
                              },
                              {
                                'id': null,
                                'title': null,
                                'runPosition': 2,
                                'lastRequest': null,
                                'pro': false,
                                'inlinks': [],
                                'status': null,
                                'redirectTo': null,
                                'blockedByRobots': false,

                                'crawleable': true,
                                'crawled': false,
                                'crawlerInvoke': true,
                                'url': urlConcat + "sitemap.xml"
                              },
                            ]

                            db.collection('Sites').doc(eleSite.id).update({
                              mostRecentCrawl: docRefCrawl.id,
                              mostRecentCrawlDate: Date.now(),
                              n_log_crawls: eleSite.data().n_log_crawls ? firebase.firestore.FieldValue.increment(1) : 1,
                              totalErrors: 0
                            })

                            // add pages crawl
                            URLsfound.forEach(eleC => {
                              db.collection('Crawls').doc(docRefCrawl.id).update({
                                requests: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                              })

                              db.collection('Crawls').doc(docRefCrawl.id).update({
                                founds: firebase.firestore.FieldValue.arrayUnion(eleC.url)
                              })

                              db.collection('Crawls').doc(docRefCrawl.id).update({
                                pages: firebase.firestore.FieldValue.arrayUnion({
                                  id: '',
                                  title: '',
                                  runPosition: null,
                                  lastRequest: null,
                                  pro: false,
                                  inlinks: [],
                                  status: null,
                                  redirectTo: '',
                                  blockedByRobots: false,
                                  crawled: true,
                                  crawleable: true,
                                  url: eleC.url,
                                  isSitemap: false,
                                  hasRun: false,
                                  active: false,
                                  size: 999999999,
                                  tries: 1,
                                  tryTimestamp: Date.now()
                                })
                              })
                              .catch(e => console.log('Error update master page: ', e.message))
                            })

                            // LOGIC RUN INVOKE CRAWL
                            var protocolSite = ''
                            var homepageSite = ''
                            if(eleSite.data().stringSearch.length > 0) {
                              body.stringSearch = eleSite.data().stringSearch
                            }
                            protocolSite = eleSite.data().prot ? eleSite.data().prot : getUrl.protocol
                            body.url = protocolSite + "//" + hostUrl + (getUrl.pathname === '/' ? '' : getUrl.pathname)
                            homepageSite = eleSite.data().homepage ? eleSite.data().homepage : ''
                            body.takeScreenshots = 'no'
                            body.triggeredBy = eleSite.id
                            body.source = docRefCrawl.id
                            body.type = 'only-urls'
                            body.useFastCrawler = 'no'
                            body.typePage = 'homeXML'
                            body.parseAsTextContent = 'yes'
                            body.screenshotComment = ''

                            var urlOrigin = body.url
                            
                            // var config = {
                            //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                            //   "method": "POST",
                            //   "timeout": 0,
                            //   "headers": {
                            //     "Content-Type": "application/json",
                            //     "Accept": "application/json"
                            //   },
                            //   "data": JSON.stringify(body),
                            // };

                            var config = {
                              method: 'POST',
                              url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                              maxBodyLength: Infinity,
                              timeout: 0,
                              headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                              },
                              data: JSON.stringify(body)
                            };

                            this.axios(config)
                            .then((response) => {
                              body.url = urlOrigin
                              body.takeScreenshots = 'no'
                              body.type = 'full'
                              body.typePage = 'normal'
                              // console.log(response.data)
                              console.log('invoke home')
                            })
                            .catch(e => {
                              console.log(e)
                            })
                            
                            // run crawl three initials xml
                            // body.typePage = 'normal'
                            var atomicBody = body
                            var countInvocked = 0
                            let arraySites = [atomicBody.url + (atomicBody.url.endsWith('/') ? 'sitemap.xml' : '/sitemap.xml')]
                            // let arraySites = []
                            arraySites.forEach(res => {
                              var objXMLCrawl = {
                                forceNotFound: 'yes',
                                id: body.id,
                                ignoreIndexNoFollow: 'yes',
                                pageSize: 999999999,
                                screenshotComment: '',
                                screenshotType: 'test',
                                source: body.source,
                                stringSearch: body.stringSearch,
                                takeScreenshots: 'no',
                                triggeredBy: body.triggeredBy,
                                type: 'only-urls',
                                url: res,
                                useFastCrawler: 'no',
                                typePage: 'normal',
                                parseAsTextContent: 'no'
                              }
                              atomicBody.url = res
                              atomicBody.type = 'only-urls'
                              atomicBody.takeScreenshots = 'no'
                              atomicBody.typePage = 'normal'
                              
                              // var config = {
                              //   "url": "https://app-facm3chzha-uc.a.run.app/crawl-url",
                              //   "method": "POST",
                              //   "timeout": 0,
                              //   "headers": {
                              //     "Content-Type": "application/json",
                              //     "Accept": "application/json"
                              //   },
                              //   "data": JSON.stringify(atomicBody),
                              // };
                              body.url = urlOrigin
                              body.type = 'full'

                              var config = {
                                method: 'POST',
                                url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/startCrawl/start-crawl',
                                maxBodyLength: Infinity,
                                timeout: 0,
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Accept': 'application/json'
                                },
                                data: JSON.stringify(objXMLCrawl)
                              };

                              this.axios(config)
                              .then((response) => {
                                countInvocked += 1
                                console.log('invoke init xml')
                                body.stringSearch = ["PHP ERROR",'Lorem','Ipsum']
                                body.takeScreenshots = 'no'
                                body.screenshotComment = ""
                                body.url = urlOrigin
                              })
                              .catch(e => {
                                console.log(e)
                              })
                            })

                            // this.itemsPagesOverview = []
                            db.collection('Sites').doc(eleSite.id).update({
                              mostRecentCrawlStatus: 'running',
                              mostRecentCrawl: docRefCrawl.id,
                              mostRecentCrawlId: body.id,
                              pages: [],
                              // pageSizes: [],
                              externalLinks: []
                            })
                            .catch(e => console.log('Error update site: ', e))

                            db.collection('Sites_cache').doc(eleSite.id).update({
                              mostRecentCrawlStatus: 'running',
                              mostRecentCrawl: docRefCrawl.id,
                              mostRecentCrawlId: body.id,
                              inlinks: [],
                              pages: []
                            })
                            .catch(e => console.log('Error update site: ', e))

                            URLsfound.forEach(eleC => {
                              var objCache = {}
                              objCache.id = null
                              objCache.title = null
                              objCache.url = eleC.url
                              objCache.inlinks = null
                              objCache.inlinksNumber = null
                              objCache.pro = false
                              objCache.status = null
                              objCache.redirectTo = null
                              objCache.blockedByRobots = null
                              objCache.totalErrors = 0
                              objCache.errorByType = {}
                              objCache.isSitemap = eleC.url.includes('.xml') ? true : false
                              db.collection('Sites_cache').doc(eleSite.id).update({
                                pages: firebase.firestore.FieldValue.arrayUnion(objCache)
                              })

                              var objPageCollection = {
                                id: null,
                                title: null,
                                url: eleC.url,
                                inlinks: [],
                                inlinksNumber: 0,
                                pro: false,
                                // status: itemPage.status,
                                status: null,
                                redirectTo: null,
                                blockedByRobots: null,
                                totalErrors: 0,
                                errorByType: {},
                                active: false,
                                crawleable: true,
                                size: null,
                                isSitemap: eleC.url.includes('.xml') ? true : false
                              }

                              db.collection('Sites').doc(eleSite.id).collection('pages').where('url', '==', eleC.url).limit(1).get()
                              .then(docSite => {
                                if(docSite.size) {
                                  var counterLoop = 0
                                  docSite.forEach(ele => {
                                    if(counterLoop === 0) {
                                      db.collection('Sites').doc(eleSite.id).collection('pages').doc(ele.id).update(objPageCollection)
                                    }
                                  })
                                } else {
                                  db.collection('Sites').doc(eleSite.id).collection('pages').add(objPageCollection)
                                  .then((docRefSite) => {
                                      db.collection('Sites').doc(eleSite.id).collection('pages').doc(docRefSite.id).update({
                                        id: docRefSite.id
                                      })
                                  })
                                }
                              })
                            })
                          })
                          .catch(e => console.log('Error add crawl site: ', e))
                        } else if(queryCrawl.size) {
                          console.log('Crawl running already!')
                        }
                      })
                      .catch(e => console.log(e.message))
                    })
                    .catch(e => {
                      console.log('Error query sites host: ', e)
                    })
                  }

                  setTimeout(() => {
                    localStorage.setItem('createSite', true)
                    localStorage.setItem('reloadSearchSites', true)

                    // this.$toast({
                    //   component: ToastificationContent,
                    //   position: 'top-right',
                    //   props: {
                    //     title: `Site created successfully`,
                    //     icon: 'CoffeeIcon',
                    //     variant: 'success'
                    //   },
                    // })
                  }, 1500)

                  db.collection('Projects').doc('guess').update({
                    numberSites: firebase.firestore.FieldValue.increment(1),
                  })

                  db.collection('Sites').where('WebsiteURL', '==', this.$route.query.site).limit(1).get()
                  .then(siteQueryWebsite => {
                    if(siteQueryWebsite.size) {
                      siteQueryWebsite.forEach(eleWebsiteSite => {
                        var projectWebsite = eleWebsiteSite.data().projects[0]
                        db.collection('Sites').doc(eleWebsiteSite.id).get()
                        .then(site => {
                          if(site.exists) {
                            // console.log(site.data().owner,user.uid)
                            // console.log(site.data().thumbnail)
                            // this.siteObj = []
                            var projectID = projectWebsite
                            this.idsite.domain = site.data().WebsiteURL
                            this.idsite.name = site.data().name ? site.data().name : site.data().homepage
                            this.idsite.idsite = projectID
                            this.idsite.imgSite = site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png'
                            this.idsite.permission = site.data().owner && site.data().owner.uid === this.userData.uid ? 'owner' : 'edit'
                            // console.log(this.idsite.permission)
                            var siteHomepage = site.data().homepage
                            // var SiteID = site.id
                            var domainPrefix = site.data().domainPrefix ? site.data().domainPrefix : ''
                            // var siteWebsiteURL = site.data().WebsiteURL
                            if(this.siteObj.length === 0) {
                              if(site.data().project) {
                                // db.collection('Projects').doc(site.data().project).get()
                                // .then(projects => {
                                //   if(projects.exists) {
                                    // db.collection('Projects').doc(site.data().project).collection('members').where('uid', '!=', 'resume').get()
                                    // .then(invitationsProject => {
                                    //   var arrayInvitations = []
                                    //   if(invitationsProject.size >= 1) {
                                    //     invitationsProject.forEach(inv => {
                                    //       arrayInvitations.push(inv.data())
                                    //     })
                                    //   }
                                    //   var isMember = arrayInvitations.filter(ele => ele.uid === site.data().createdBy || (site.data().owned && ele.uid === site.data().owned.uid) || projects.data().owned.uid === site.data().createdBy || (site.data().owned && projects.data().owned.uid === site.data().owned.uid))
                                      
                                      this.siteObj.push({
                                        imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                                        name: site.data().name ? site.data().name : site.data().homepage,
                                        domain: site.data().homepage,
                                        currentErrors: '267',
                                        fixedIssues: '79',
                                        key: site.id,
                                        alerts: '3',
                                        membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
                                        ownedBy: site.data().owner && site.data().owner.uid === this.userData.uid ? site.data().owner : {},
                                        owner: 'me',
                                        label: '',
                                        color: '',
                                        id: site.id,
                                        projectid: projectWebsite
                                      })
                                    // })
                                  // }
                                // })
                              } else {
                                this.siteObj.push({
                                  imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                                  name: site.data().name ? site.data().name : site.data().homepage,
                                  domain: site.data().homepage,
                                  currentErrors: '267',
                                  fixedIssues: '79',
                                  key: site.id,
                                  alerts: '3',
                                  membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
                                  ownedBy: site.data().owner && site.data().owner.uid === this.userData.uid ? site.data().owner : {},
                                  owner: 'me',
                                  label: '',
                                  color: '',
                                  id: site.id,
                                  projectid: projectWebsite
                                })
                              }
                                // this.crawlRunning = false
                                // this.crawlID = site.data().mostRecentCrawl
                                // this.crawls.estatus === 'running' ? this.crawls = {} : ''
                                // this.crawls.id = site.data().mostRecentCrawl
                                // this.crawls.estatus = site.data().mostRecentCrawlStatus
                                // this.crawls.availableURL = site.data().totalPages
                                // this.crawls.date = moment(site.data().mostRecentCrawlDate).utcOffset("-06:00").format("Do MMMM YYYY")
                                var arrayPages = site.data().pages && site.data().pages.length >= 1 ? site.data().pages : []
                                // var totalRequest = arrayPages.filter(ele => ele.status !== null)
                                // this.crawls.requests = totalRequest.length
                                var inlinks = site.data().inlinks && site.data().inlinks.length ? site.data().inlinks : []
                                var proPages = site.data().proPages && site.data().proPages.length ? site.data().proPages : []
                                var docsExternals = site.data().externalLinks && site.data().externalLinks.length >= 1 ? site.data().externalLinks : []
                                var docsObjErrors = site.data().errorsPages && site.data().errorsPages.length >= 1 ? site.data().errorsPages : []
                                var arrayProPages = []
                                var array200Pages = []
                                var array200XML = []
                                var array302Pages = []
                                var array301Pages = []
                                var array404Pages = []
                                var arrayTimeoutPages = []
                                var arrayOtherPages = []
                                var idListPage = 0
                                this.listPagesSidebars = []
                                var filterInlinksHome = []
                                arrayPages.forEach(itemPage => {
                                  var URLactual = new URL(itemPage.url)
                                  if((itemPage.url.includes('.xml') && itemPage.status === 200) || (itemPage.status && !itemPage.url.includes('.xml'))) {
                                    var filterPage = this.listPagesSidebars.filter(ele => ele.cUrl === itemPage.url)
                                    var filterHomePage = this.listPagesSidebars.filter(ele => ele.id === 0)
                                    // var idListPage = incrementalIdPages
                                    var filterInlinksPage = inlinks.filter(item => item.link === itemPage.url)
                                    var filterPro = proPages.filter(ele => ele === itemPage.url)
                                    var filterExternals = docsExternals.filter(item => item.source === itemPage.url)
                                    var filterObjErrors = docsObjErrors.filter(errObj => errObj.url === itemPage.url)
                                    
                                    if(URLactual.pathname === '/') {
                                      var urlcrawl = new URL(siteHomepage)
                                      var urlProtocol = urlcrawl.protocol
                                      var URLdomainFound = urlcrawl.host
                                      var URLwwwFound = ''
                                      var URLwithoutWWWFound = ''
                                      if(URLdomainFound.includes('www.')) {
                                        URLwwwFound = urlProtocol + '//' + URLdomainFound
                                        URLwithoutWWWFound = urlProtocol + '//' + URLdomainFound.slice(4)
                                      } else {
                                        URLwwwFound = urlProtocol + '//' + 'www.'+URLdomainFound
                                        URLwithoutWWWFound = urlProtocol + '//' + URLdomainFound
                                      }
                                      filterInlinksHome = inlinks.filter(item => item.link === siteHomepage || item.link === siteHomepage+ '/' || item.link === URLwwwFound || item.link === URLwithoutWWWFound || item.link === URLwwwFound + '/' || item.link === URLwithoutWWWFound + '/')
                                    }

                                    if(URLactual.pathname === '/' && filterHomePage.length === 0) {
                                    // if(URLactual.pathname === '/') {
                                      // var filterInlinksPageHome = inlinks.filter(item => item.link === itemPage.url || item.link === itemPage.url + '/')
                                      this.listPagesSidebars.unshift({
                                        id: 0,
                                        selected: false,
                                        page: 'home',
                                        title: itemPage.title ? itemPage.title : 'home',
                                        cTitle: itemPage.title ? itemPage.title : '',
                                        pro: true,
                                        url: 'Homepage',
                                        // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                        inlinks: filterInlinksHome.length,
                                        inlinksList: filterInlinksHome,
                                        errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                        name: 'a',
                                        cUrl: itemPage.url,
                                        status: itemPage.status,
                                        redirectTo: itemPage.redirectTo
                                        // exist: true,
                                      })
                                    } else if(filterPage.length === 0) {
                                      if(URLactual.pathname !== '/') {
                                        idListPage += 1
                                        if(filterPro.length >= 1) {
                                          var filterP = arrayProPages.filter(ele => ele.cUrl === itemPage.url)
                                          if(filterP.length) {
                                            idListPage -= 1
                                          } else {
                                            arrayProPages.push({
                                              id: idListPage,
                                              selected: false,
                                              page: URLactual.pathname,
                                              title: itemPage.title ? itemPage.title : URLactual.pathname,
                                              cTitle: itemPage.title ? itemPage.title : '',
                                              pro: true,
                                              url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                              // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                              inlinks: filterInlinksPage.length,
                                              inlinksList: filterInlinksPage,
                                              errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                              name: URLactual.pathname,
                                              cUrl: itemPage.url,
                                              status: itemPage.status,
                                              redirectTo: itemPage.redirectTo
                                              // exist: true,
                                            })
                                          }
                                        } else if(itemPage.status === 200) {
                                          if(itemPage.url.includes('.xml')) {
                                            var filterP = array200XML.filter(ele => ele.cUrl === itemPage.url)
                                            if(filterP.length) {
                                              idListPage -= 1
                                            } else {
                                              array200XML.push({
                                                id: idListPage,
                                                selected: false,
                                                page: URLactual.pathname,
                                                title: itemPage.title ? itemPage.title : URLactual.pathname,
                                                cTitle: itemPage.title ? itemPage.title : '',
                                                pro: itemPage.pro ? true : false,
                                                url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                                // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                                inlinks: filterInlinksPage.length,
                                                inlinksList: filterInlinksPage,
                                                errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                                name: URLactual.pathname,
                                                cUrl: itemPage.url,
                                                status: itemPage.status,
                                                redirectTo: itemPage.redirectTo
                                                // exist: true,
                                              })
                                            }
                                          } else {
                                            var filterP = array200Pages.filter(ele => ele.cUrl === itemPage.url)
                                            if(filterP.length) {
                                              idListPage -= 1
                                            } else {
                                              array200Pages.push({
                                                id: idListPage,
                                                selected: false,
                                                page: URLactual.pathname,
                                                title: itemPage.title ? itemPage.title : URLactual.pathname,
                                                cTitle: itemPage.title ? itemPage.title : '',
                                                pro: itemPage.pro ? true : false,
                                                url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                                // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                                inlinks: filterInlinksPage.length,
                                                inlinksList: filterInlinksPage,
                                                errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                                name: URLactual.pathname,
                                                cUrl: itemPage.url,
                                                status: itemPage.status,
                                                redirectTo: itemPage.redirectTo
                                                // exist: true,
                                              })
                                            }
                                          }
                                        } else if(itemPage.status === 302) {
                                          var filterP = array302Pages.filter(ele => ele.cUrl === itemPage.url)
                                          if(filterP.length) {
                                            idListPage -= 1
                                          } else {
                                            array302Pages.push({
                                              id: idListPage,
                                              selected: false,
                                              page: URLactual.pathname,
                                              title: itemPage.title ? itemPage.title : URLactual.pathname,
                                              cTitle: itemPage.title ? itemPage.title : '',
                                              pro: itemPage.pro ? true : false,
                                              url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                              // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                              inlinks: filterInlinksPage.length,
                                              inlinksList: filterInlinksPage,
                                              errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                              name: URLactual.pathname,
                                              cUrl: itemPage.url,
                                              status: itemPage.status,
                                              redirectTo: itemPage.redirectTo
                                              // exist: true,
                                            })
                                          }
                                        } else if(itemPage.status === 301) {
                                          var filterP = array301Pages.filter(ele => ele.cUrl === itemPage.url)
                                          if(filterP.length) {
                                            idListPage -= 1
                                          } else {
                                            array301Pages.push({
                                              id: idListPage,
                                              selected: false,
                                              page: URLactual.pathname,
                                              title: itemPage.title ? itemPage.title : URLactual.pathname,
                                              cTitle: itemPage.title ? itemPage.title : '',
                                              pro: itemPage.pro ? true : false,
                                              url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                              // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                              inlinks: filterInlinksPage.length,
                                              inlinksList: filterInlinksPage,
                                              errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                              name: URLactual.pathname,
                                              cUrl: itemPage.url,
                                              status: itemPage.status,
                                              redirectTo: itemPage.redirectTo
                                              // exist: true,
                                            })
                                          }
                                        } else if(itemPage.status === 404) {
                                          var filterP = array404Pages.filter(ele => ele.cUrl === itemPage.url)
                                          if(filterP.length) {
                                            idListPage -= 1
                                          } else {
                                            array404Pages.push({
                                              id: idListPage,
                                              selected: false,
                                              page: URLactual.pathname,
                                              title: itemPage.title ? itemPage.title : URLactual.pathname,
                                              cTitle: itemPage.title ? itemPage.title : '',
                                              pro: itemPage.pro ? true : false,
                                              url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                              // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                              inlinks: filterInlinksPage.length,
                                              inlinksList: filterInlinksPage,
                                              errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                              name: URLactual.pathname,
                                              cUrl: itemPage.url,
                                              status: itemPage.status,
                                              redirectTo: itemPage.redirectTo
                                              // exist: true,
                                            })
                                          }
                                        } else if(itemPage.status === 'timeout') {
                                          var filterP = arrayTimeoutPages.filter(ele => ele.cUrl === itemPage.url)
                                          if(filterP.length) {
                                            idListPage -= 1
                                          } else {
                                            arrayTimeoutPages.push({
                                              id: idListPage,
                                              selected: false,
                                              page: URLactual.pathname,
                                              title: itemPage.title ? itemPage.title : URLactual.pathname,
                                              cTitle: itemPage.title ? itemPage.title : '',
                                              pro: itemPage.pro ? true : false,
                                              url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                              // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                              inlinks: filterInlinksPage.length,
                                              inlinksList: filterInlinksPage,
                                              errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                              name: URLactual.pathname,
                                              cUrl: itemPage.url,
                                              status: itemPage.status,
                                              redirectTo: itemPage.redirectTo
                                              // exist: true,
                                            })
                                          }
                                        } else {
                                          var filterP = arrayOtherPages.filter(ele => ele.cUrl === itemPage.url)
                                          if(filterP.length) {
                                            idListPage -= 1
                                          } else {
                                            arrayOtherPages.push({
                                              id: idListPage,
                                              selected: false,
                                              page: URLactual.pathname,
                                              title: itemPage.title ? itemPage.title : URLactual.pathname,
                                              cTitle: itemPage.title ? itemPage.title : '',
                                              pro: itemPage.pro ? true : false,
                                              url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                              // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                              inlinks: filterInlinksPage.length,
                                              inlinksList: filterInlinksPage,
                                              errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                              name: URLactual.pathname,
                                              cUrl: itemPage.url,
                                              status: itemPage.status,
                                              redirectTo: itemPage.redirectTo
                                              // exist: true,
                                            })
                                          }
                                        }
                                      }
                                    } else {
                                      if(filterPro.length >= 1) {
                                        filterPage.forEach(elePro => {
                                          elePro.pro = true
                                          elePro.status = itemPage.status
                                          elePro.errors = filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0)
                                        })
                                      } else if(URLactual.pathname === '/') {
                                        filterPage.forEach(elePro => {
                                          // elePro.pro = true
                                          elePro.status = itemPage.status
                                          elePro.errors = filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0)
                                        })
                                      }
                                    }
                                  }
                                })

                                var filterHomePage = this.listPagesSidebars.filter(ele => ele.id === 0)
                                if(filterHomePage.length === 0) {
                                  this.listPagesSidebars.unshift({
                                    id: 0,
                                    selected: false,
                                    page: 'home',
                                    title: 'home',
                                    pro: true,
                                    url: 'Homepage',
                                    inlinks: filterInlinksHome.length,
                                    inlinksList: filterInlinksHome,
                                    errors: 0,
                                    name: 'a',
                                    cUrl: siteHomepage,
                                    status: 'timeout'
                                    // exist: true,
                                  })
                                }

                                var filterStatusHome = arrayPages.filter(ele => ele.url === siteHomepage && ele.status !== null)
                                if(filterHomePage.length && filterStatusHome.length) {
                                  // console.log(filterStatusHome)
                                  if(filterStatusHome.length === 1) {
                                    this.listPagesSidebars[0].status = filterStatusHome[0].status
                                  } else {
                                    var status200 = filterStatusHome.filter(ele => ele.status === 200)
                                    var status302 = filterStatusHome.filter(ele => ele.status === 302)
                                    var status301 = filterStatusHome.filter(ele => ele.status === 301)
                                    var status404 = filterStatusHome.filter(ele => ele.status === 404)
                                    if(status200.length) {
                                      this.listPagesSidebars[0].status = status200[0].status
                                    } else if(status302.length) {
                                      this.listPagesSidebars[0].status = status302[0].status
                                    } else if(status301.length) {
                                      this.listPagesSidebars[0].status = status301[0].status
                                    } else if(status404.length) {
                                      this.listPagesSidebars[0].status = status404[0].status
                                    } else {
                                      this.listPagesSidebars[0].status = filterStatusHome[0].status
                                    }
                                  }
                                }

                                var condicionParaOrdenar = (pageA, pageB) => {
                                  return pageB.inlinks - pageA.inlinks;
                                }
                                arrayProPages.sort(condicionParaOrdenar)
                                array200Pages.sort(condicionParaOrdenar)
                                array200XML.sort(condicionParaOrdenar)
                                array302Pages.sort(condicionParaOrdenar)
                                array301Pages.sort(condicionParaOrdenar)
                                array404Pages.sort(condicionParaOrdenar)
                                arrayTimeoutPages.sort(condicionParaOrdenar)
                                arrayOtherPages.sort(condicionParaOrdenar)
                                var incrementalIdPages = 0

                                // array200Pages.push({
                                //   pro: false,
                                //   cUrl: 'https://viventi.com.gt/test2',
                                //   status: 200,
                                //   id: 1,
                                //   selected: false,
                                //   page: 'test',
                                //   title: 'test',
                                //   cTitle: 'test',
                                //   url: '/test2',
                                //   inlinks: 0,
                                //   inlinksList: [],
                                //   errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                //   name: 'test',
                                //   redirectTo: null
                                // })

                                arrayProPages.forEach(ele => {
                                  incrementalIdPages += 1
                                  this.listPagesSidebars.push({
                                    id: incrementalIdPages,
                                    selected: ele.selected,
                                    page: ele.page,
                                    title: ele.title,
                                    cTitle: ele.cTitle,
                                    pro: ele.pro,
                                    url: ele.url,
                                    inlinks: ele.inlinks,
                                    inlinksList: ele.inlinksList,
                                    errors: ele.errors,
                                    name: ele.name,
                                    cUrl: ele.cUrl,
                                    status: ele.status,
                                    redirectTo: ele.redirectTo
                                    // exist: true,
                                  })
                                })
                                
                                array200Pages.forEach(ele => {
                                  incrementalIdPages += 1
                                  this.listPagesSidebars.push({
                                    id: incrementalIdPages,
                                    selected: ele.selected,
                                    page: ele.page,
                                    title: ele.title,
                                    cTitle: ele.cTitle,
                                    pro: ele.pro,
                                    url: ele.url,
                                    inlinks: ele.inlinks,
                                    inlinksList: ele.inlinksList,
                                    errors: ele.errors,
                                    name: ele.name,
                                    cUrl: ele.cUrl,
                                    status: ele.status,
                                    redirectTo: ele.redirectTo
                                    // exist: true,
                                  })
                                })

                                // array302Pages.forEach(ele => {
                                //   incrementalIdPages += 1
                                //   this.listPagesSidebars.push({
                                //     id: incrementalIdPages,
                                //     selected: ele.selected,
                                //     page: ele.page,
                                //     title: ele.title,
                                //     cTitle: ele.cTitle,
                                //     pro: ele.pro,
                                //     url: ele.url,
                                //     inlinks: ele.inlinks,
                                //     inlinksList: ele.inlinksList,
                                //     errors: ele.errors,
                                //     name: ele.name,
                                //     cUrl: ele.cUrl,
                                //     status: ele.status,
                                //     redirectTo: ele.redirectTo
                                //     // exist: true,
                                //   })
                                // })

                                // array200XML.forEach(ele => {
                                //   incrementalIdPages += 1
                                //   this.listPagesSidebars.push({
                                //     id: incrementalIdPages,
                                //     selected: ele.selected,
                                //     page: ele.page,
                                //     title: ele.title,
                                //     cTitle: ele.cTitle,
                                //     pro: ele.pro,
                                //     url: ele.url,
                                //     inlinks: ele.inlinks,
                                //     inlinksList: ele.inlinksList,
                                //     errors: ele.errors,
                                //     name: ele.name,
                                //     cUrl: ele.cUrl,
                                //     status: ele.status,
                                //     redirectTo: ele.redirectTo
                                //     // exist: true,
                                //   })
                                // })

                                // array301Pages.forEach(ele => {
                                //   incrementalIdPages += 1
                                //   this.listPagesSidebars.push({
                                //     id: incrementalIdPages,
                                //     selected: ele.selected,
                                //     page: ele.page,
                                //     title: ele.title,
                                //     cTitle: ele.cTitle,
                                //     pro: ele.pro,
                                //     url: ele.url,
                                //     inlinks: ele.inlinks,
                                //     inlinksList: ele.inlinksList,
                                //     errors: ele.errors,
                                //     name: ele.name,
                                //     cUrl: ele.cUrl,
                                //     status: ele.status,
                                //     redirectTo: ele.redirectTo
                                //     // exist: true,
                                //   })
                                // })

                                // array404Pages.forEach(ele => {
                                //   incrementalIdPages += 1
                                //   this.listPagesSidebars.push({
                                //     id: incrementalIdPages,
                                //     selected: ele.selected,
                                //     page: ele.page,
                                //     title: ele.title,
                                //     cTitle: ele.cTitle,
                                //     pro: ele.pro,
                                //     url: ele.url,
                                //     inlinks: ele.inlinks,
                                //     inlinksList: ele.inlinksList,
                                //     errors: ele.errors,
                                //     name: ele.name,
                                //     cUrl: ele.cUrl,
                                //     status: ele.status,
                                //     redirectTo: ele.redirectTo
                                //     // exist: true,
                                //   })
                                // })

                                // arrayTimeoutPages.forEach(ele => {
                                //   incrementalIdPages += 1
                                //   this.listPagesSidebars.push({
                                //     id: incrementalIdPages,
                                //     selected: ele.selected,
                                //     page: ele.page,
                                //     title: ele.title,
                                //     cTitle: ele.cTitle,
                                //     pro: ele.pro,
                                //     url: ele.url,
                                //     inlinks: ele.inlinks,
                                //     inlinksList: ele.inlinksList,
                                //     errors: ele.errors,
                                //     name: ele.name,
                                //     cUrl: ele.cUrl,
                                //     status: ele.status,
                                //     redirectTo: ele.redirectTo
                                //     // exist: true,
                                //   })
                                // })

                                // arrayOtherPages.forEach(ele => {
                                //   incrementalIdPages += 1
                                //   this.listPagesSidebars.push({
                                //     id: incrementalIdPages,
                                //     selected: ele.selected,
                                //     page: ele.page,
                                //     title: ele.title,
                                //     cTitle: ele.cTitle,
                                //     pro: ele.pro,
                                //     url: ele.url,
                                //     inlinks: ele.inlinks,
                                //     inlinksList: ele.inlinksList,
                                //     errors: ele.errors,
                                //     name: ele.name,
                                //     cUrl: ele.cUrl,
                                //     status: ele.status,
                                //     redirectTo: ele.redirectTo
                                //     // exist: true,
                                //   })
                                // })
                              
                            } else {
                              this.siteObj[0].ownedBy = site.data().owner && site.data().owner.uid === this.userData.uid ? site.data().owner : {}
                              this.siteObj[0].imgSite = site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png'
                            }
                          }
                        })

                        if(this.$route.name === 'project-sites' || this.$route.name === 'view-errors' || this.$route.name === 'shared-site') {
                          // console.log(this.$route.params.id)
                          var projectID = projectWebsite
                          db.collection('Projects').doc(projectID).get()
                          .then(projects => {
                            // console.log(projects.exists)
                            if(projects.exists) {
                              this.owner = 'me'
                              this.colorProject = projects.data().color ? projects.data().color : ''
                              this.invitationsProject = 0
                              this.invitationsProject = 0
                              this.title = projects.data().name ? projects.data().name : ''
                              this.idsite.label = projects.data().name ? projects.data().name : ''
                              this.idsite.color = projects.data().color ? projects.data().color : ''

                              // array of members
                              this.arrayInvitations = []
                            } else {
                              this.idsite.idsite = projectID ? projectID : ''
                              this.idsite.label = 'My sites'
                              this.idsite.color = '#181E38'
                              this.idsite.permission = 'owner'
                            }
                          })
                        }
                      })
                    }
                  })
                })
              }
            } else {
              this.siteNotFound = true
            }
          }
        })
      } else if(user) {
        // this.idsite = {}
        this.userData = {}
        this.userData.uid = user.uid
        // if(this.$route.name !== 'view-errors') {
        //   this.siteObj = []
        // }

        db.collection('Users').doc(user.uid).get()
        .then(userDoc => {
          // this.planLicensed = ''
          var projectID = this.$route.name === 'project-sites' ? this.$route.params.id : this.$route.name === 'view-errors' || this.$route.name === 'shared-site' ? this.$route.params.project : this.$route.params.id
          var teamsVar = userDoc.data().teams && userDoc.data().teams.length ? userDoc.data().teams : []
          if(teamsVar.includes(projectID)) {
            db.collection('Sites').doc(this.$route.params.id)
            // .onSnapshot(site => {
            .get().then(site => {
              if(site.exists) {
                this.statusLastCrawl = site.data().mostRecentCrawlStatus

                // check status script
                if(this.$route.name === 'view-errors' && site.data().mostRecentCrawlStatus !== 'running') {
                  var projectID = this.$route.name === 'project-sites' ? this.$route.params.id : this.$route.name === 'view-errors' || this.$route.name === 'shared-site' ? this.$route.params.project : this.$route.params.id
                  db.collection('Projects').doc(projectID).collection('sites').doc(site.id).get()
                  .then(siteDoc => {
                    if(siteDoc.exists) {
                      var atomVarCancel = siteDoc.data().cancelSub ? siteDoc.data().cancelSub : false
                      var downgrade_date = siteDoc.data().downgrade_date ? siteDoc.data().downgrade_date : false
                      var downgrade_planed = false
                      var nextDueCancel = siteDoc.data().due_end ? moment(siteDoc.data().due_end*1000).format('ll') : siteDoc.data().due ? moment(siteDoc.data().due*1000).add(1, 'month').format('ll') : moment(Date.now()).add(1, 'month').format('ll')
                      var subscriptionId = siteDoc.data().subscriptionId ? siteDoc.data().subscriptionId : ''
                      if(downgrade_date) {
                        var dueCancel = moment(downgrade_date*1000)
                        var dateActual = moment()

                        // Diferencia en días
                        var differenceInMinutes = dueCancel.diff(dateActual, 'minutes');
                        // console.log(differenceInMinutes, dueCancel, dateActual);
                        if(differenceInMinutes < 0) {
                          downgrade_planed = false
                          db.collection('Projects').doc(projectID).collection('sites').doc(site.id).update({
                            downgrade_date: ''
                          })
                        } else {
                          downgrade_planed = true
                        }
                      }
                      this.cancelSub = atomVarCancel ? true : downgrade_planed === true && nextDueCancel && subscriptionId ? true : false
                    }

                    // console.log(siteDoc.data().owner && siteDoc.data().owner.uid)
                    if(siteDoc.data().owner && siteDoc.data().owner.uid) {
                      // console.log(user.uid, siteDoc.data().owner.uid, siteDoc.data().homepage)
                      // check script exitense
                      // console.log(user.uid === siteDoc.data().owner.uid)
                      if(user.uid === siteDoc.data().owner.uid) {
                        if(!this.processingScript) {
                          this.processingScript = true
                          var getFirstUser = user.uid.slice(0, 4);
                          var getFirstSite = site.id.slice(0, 4);
                          var keyID = getFirstUser + getFirstSite

                          var obj = {
                            "url": siteDoc.data().homepage,
                            // "scriptSrc": `https://storage.googleapis.com/jsasync/${keyID}`
                            "scriptSrc": `https://cdn.seocloud.ai/${keyID}`
                          }

                          var config = {
                            method: 'POST',
                            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/check-script',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            data: JSON.stringify(obj),
                          }

                          this.axios(config)
                          .then(response => {
                            // console.log('header: ',response.data,siteDoc.data().homepage)
                            // console.log(response.data.scriptPresent)
                            if(!response.data.scriptPresent) {
                              this.isNotAlreadyOwner = false
                            } else {
                              this.isNotAlreadyOwner = true
                            }

                            setTimeout(() => {
                              this.processingScript = false
                            }, 6000)
                          })
                          .catch(e => {
                            this.processingScript = false
                          })
                        }
                      } else {
                        this.isNotAlreadyOwner = false
                      }
                    } else {
                      // console.log(siteDoc.data().owners)
                      // history owner lost
                      if(siteDoc.data().owners && siteDoc.data().owners.length) {
                        if(siteDoc.data().owners.includes(user.uid)) {
                          this.isNotAlreadyOwner = false
                        }
                      }
                      // else {
                      //   this.isNotAlreadyOwner = false
                      // }
                    }
                  })
                }

                // console.log(site.data().owner,user.uid)
                // console.log(site.data().thumbnail)
                // this.siteObj = []
                var projectID = this.$route.name === 'project-sites' ? this.$route.params.id : this.$route.name === 'view-errors' || this.$route.name === 'shared-site' ? this.$route.params.project : this.$route.params.id
                this.idsite.domain = site.data().WebsiteURL
                this.idsite.name = site.data().name ? site.data().name : site.data().homepage
                this.idsite.idsite = projectID
                this.idsite.imgSite = site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png'
                this.idsite.permission = site.data().owner && site.data().owner.uid === user.uid ? 'owner' : 'edit'
                // console.log(this.idsite.permission)
                var siteHomepage = site.data().homepage
                // var SiteID = site.id
                var domainPrefix = site.data().domainPrefix ? site.data().domainPrefix : ''
                // var siteWebsiteURL = site.data().WebsiteURL
                if(this.siteObj.length === 0) {
                  if(site.data().project) {
                    // db.collection('Projects').doc(site.data().project).get()
                    // .then(projects => {
                    //   if(projects.exists) {
                        // db.collection('Projects').doc(site.data().project).collection('members').where('uid', '!=', 'resume').get()
                        // .then(invitationsProject => {
                        //   var arrayInvitations = []
                        //   if(invitationsProject.size >= 1) {
                        //     invitationsProject.forEach(inv => {
                        //       arrayInvitations.push(inv.data())
                        //     })
                        //   }
                        //   var isMember = arrayInvitations.filter(ele => ele.uid === site.data().createdBy || (site.data().owned && ele.uid === site.data().owned.uid) || projects.data().owned.uid === site.data().createdBy || (site.data().owned && projects.data().owned.uid === site.data().owned.uid))
                          
                          this.siteObj.push({
                            imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                            name: site.data().name ? site.data().name : site.data().homepage,
                            domain: site.data().homepage,
                            currentErrors: '267',
                            fixedIssues: '79',
                            key: site.id,
                            alerts: '3',
                            membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
                            ownedBy: site.data().owner && site.data().owner.uid === user.uid ? site.data().owner : {},
                            owner: 'me',
                            label: '',
                            color: '',
                            id: site.id,
                            projectid: this.$route.params.project ? this.$route.params.project : this.$route.params.id ? this.$route.params.id : '',
                          })
                        // })
                      // }
                    // })
                  } else {
                    this.siteObj.push({
                      imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png',
                      name: site.data().name ? site.data().name : site.data().homepage,
                      domain: site.data().homepage,
                      currentErrors: '267',
                      fixedIssues: '79',
                      key: site.id,
                      alerts: '3',
                      membership: `${site.data().plan ? site.data().plan : 'Free'} license`,
                      ownedBy: site.data().owner && site.data().owner.uid === user.uid ? site.data().owner : {},
                      owner: 'me',
                      label: '',
                      color: '',
                      id: site.id,
                      projectid: this.$route.params.project ? this.$route.params.project : this.$route.params.id ? this.$route.params.id : '',
                    })
                  }
                    // this.crawlRunning = false
                    // this.crawlID = site.data().mostRecentCrawl
                    // this.crawls.estatus === 'running' ? this.crawls = {} : ''
                    // this.crawls.id = site.data().mostRecentCrawl
                    // this.crawls.estatus = site.data().mostRecentCrawlStatus
                    // this.crawls.availableURL = site.data().totalPages
                    // this.crawls.date = moment(site.data().mostRecentCrawlDate).utcOffset("-06:00").format("Do MMMM YYYY")
                    var arrayPages = site.data().pages && site.data().pages.length >= 1 ? site.data().pages : []
                    // var totalRequest = arrayPages.filter(ele => ele.status !== null)
                    // this.crawls.requests = totalRequest.length
                    var inlinks = site.data().inlinks && site.data().inlinks.length ? site.data().inlinks : []
                    var proPages = site.data().proPages && site.data().proPages.length ? site.data().proPages : []
                    var docsExternals = site.data().externalLinks && site.data().externalLinks.length >= 1 ? site.data().externalLinks : []
                    var docsObjErrors = site.data().errorsPages && site.data().errorsPages.length >= 1 ? site.data().errorsPages : []
                    var arrayProPages = []
                    var array200Pages = []
                    var array200XML = []
                    var array302Pages = []
                    var array301Pages = []
                    var array404Pages = []
                    var arrayTimeoutPages = []
                    var arrayOtherPages = []
                    var idListPage = 0
                    this.listPagesSidebars = []
                    var filterInlinksHome = []
                    arrayPages.forEach(itemPage => {
                      var URLactual = new URL(itemPage.url)
                      if((itemPage.url.includes('.xml') && itemPage.status === 200) || (itemPage.status && !itemPage.url.includes('.xml'))) {
                        var filterPage = this.listPagesSidebars.filter(ele => ele.cUrl === itemPage.url)
                        var filterHomePage = this.listPagesSidebars.filter(ele => ele.id === 0)
                        // var idListPage = incrementalIdPages
                        var filterInlinksPage = inlinks.filter(item => item.link === itemPage.url)
                        var filterPro = proPages.filter(ele => ele === itemPage.url)
                        var filterExternals = docsExternals.filter(item => item.source === itemPage.url)
                        var filterObjErrors = docsObjErrors.filter(errObj => errObj.url === itemPage.url)
                        
                        if(URLactual.pathname === '/') {
                          var urlcrawl = new URL(siteHomepage)
                          var urlProtocol = urlcrawl.protocol
                          var URLdomainFound = urlcrawl.host
                          var URLwwwFound = ''
                          var URLwithoutWWWFound = ''
                          if(URLdomainFound.includes('www.')) {
                            URLwwwFound = urlProtocol + '//' + URLdomainFound
                            URLwithoutWWWFound = urlProtocol + '//' + URLdomainFound.slice(4)
                          } else {
                            URLwwwFound = urlProtocol + '//' + 'www.'+URLdomainFound
                            URLwithoutWWWFound = urlProtocol + '//' + URLdomainFound
                          }
                          filterInlinksHome = inlinks.filter(item => item.link === siteHomepage || item.link === siteHomepage+ '/' || item.link === URLwwwFound || item.link === URLwithoutWWWFound || item.link === URLwwwFound + '/' || item.link === URLwithoutWWWFound + '/')
                        }

                        if(URLactual.pathname === '/' && filterHomePage.length === 0) {
                        // if(URLactual.pathname === '/') {
                          // var filterInlinksPageHome = inlinks.filter(item => item.link === itemPage.url || item.link === itemPage.url + '/')
                          this.listPagesSidebars.unshift({
                            id: 0,
                            selected: false,
                            page: 'home',
                            title: itemPage.title ? itemPage.title : 'home',
                            cTitle: itemPage.title ? itemPage.title : '',
                            pro: true,
                            url: 'Homepage',
                            // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                            inlinks: filterInlinksHome.length,
                            inlinksList: filterInlinksHome,
                            errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                            name: 'a',
                            cUrl: itemPage.url,
                            status: itemPage.status,
                            redirectTo: itemPage.redirectTo
                            // exist: true,
                          })
                        } else if(filterPage.length === 0) {
                          if(URLactual.pathname !== '/') {
                            idListPage += 1
                            if(filterPro.length >= 1) {
                              var filterP = arrayProPages.filter(ele => ele.cUrl === itemPage.url)
                              if(filterP.length) {
                                idListPage -= 1
                              } else {
                                arrayProPages.push({
                                  id: idListPage,
                                  selected: false,
                                  page: URLactual.pathname,
                                  title: itemPage.title ? itemPage.title : URLactual.pathname,
                                  cTitle: itemPage.title ? itemPage.title : '',
                                  pro: true,
                                  url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                  // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                  inlinks: filterInlinksPage.length,
                                  inlinksList: filterInlinksPage,
                                  errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                  name: URLactual.pathname,
                                  cUrl: itemPage.url,
                                  status: itemPage.status,
                                  redirectTo: itemPage.redirectTo
                                  // exist: true,
                                })
                              }
                            } else if(itemPage.status === 200) {
                              if(itemPage.url.includes('.xml')) {
                                var filterP = array200XML.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  array200XML.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: itemPage.pro ? true : false,
                                    url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              } else {
                                var filterP = array200Pages.filter(ele => ele.cUrl === itemPage.url)
                                if(filterP.length) {
                                  idListPage -= 1
                                } else {
                                  array200Pages.push({
                                    id: idListPage,
                                    selected: false,
                                    page: URLactual.pathname,
                                    title: itemPage.title ? itemPage.title : URLactual.pathname,
                                    cTitle: itemPage.title ? itemPage.title : '',
                                    pro: itemPage.pro ? true : false,
                                    url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                    // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                    inlinks: filterInlinksPage.length,
                                    inlinksList: filterInlinksPage,
                                    errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                    name: URLactual.pathname,
                                    cUrl: itemPage.url,
                                    status: itemPage.status,
                                    redirectTo: itemPage.redirectTo
                                    // exist: true,
                                  })
                                }
                              }
                            } else if(itemPage.status === 302) {
                              var filterP = array302Pages.filter(ele => ele.cUrl === itemPage.url)
                              if(filterP.length) {
                                idListPage -= 1
                              } else {
                                array302Pages.push({
                                  id: idListPage,
                                  selected: false,
                                  page: URLactual.pathname,
                                  title: itemPage.title ? itemPage.title : URLactual.pathname,
                                  cTitle: itemPage.title ? itemPage.title : '',
                                  pro: itemPage.pro ? true : false,
                                  url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                  // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                  inlinks: filterInlinksPage.length,
                                  inlinksList: filterInlinksPage,
                                  errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                  name: URLactual.pathname,
                                  cUrl: itemPage.url,
                                  status: itemPage.status,
                                  redirectTo: itemPage.redirectTo
                                  // exist: true,
                                })
                              }
                            } else if(itemPage.status === 301) {
                              var filterP = array301Pages.filter(ele => ele.cUrl === itemPage.url)
                              if(filterP.length) {
                                idListPage -= 1
                              } else {
                                array301Pages.push({
                                  id: idListPage,
                                  selected: false,
                                  page: URLactual.pathname,
                                  title: itemPage.title ? itemPage.title : URLactual.pathname,
                                  cTitle: itemPage.title ? itemPage.title : '',
                                  pro: itemPage.pro ? true : false,
                                  url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                  // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                  inlinks: filterInlinksPage.length,
                                  inlinksList: filterInlinksPage,
                                  errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                  name: URLactual.pathname,
                                  cUrl: itemPage.url,
                                  status: itemPage.status,
                                  redirectTo: itemPage.redirectTo
                                  // exist: true,
                                })
                              }
                            } else if(itemPage.status === 404) {
                              var filterP = array404Pages.filter(ele => ele.cUrl === itemPage.url)
                              if(filterP.length) {
                                idListPage -= 1
                              } else {
                                array404Pages.push({
                                  id: idListPage,
                                  selected: false,
                                  page: URLactual.pathname,
                                  title: itemPage.title ? itemPage.title : URLactual.pathname,
                                  cTitle: itemPage.title ? itemPage.title : '',
                                  pro: itemPage.pro ? true : false,
                                  url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                  // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                  inlinks: filterInlinksPage.length,
                                  inlinksList: filterInlinksPage,
                                  errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                  name: URLactual.pathname,
                                  cUrl: itemPage.url,
                                  status: itemPage.status,
                                  redirectTo: itemPage.redirectTo
                                  // exist: true,
                                })
                              }
                            } else if(itemPage.status === 'timeout') {
                              var filterP = arrayTimeoutPages.filter(ele => ele.cUrl === itemPage.url)
                              if(filterP.length) {
                                idListPage -= 1
                              } else {
                                arrayTimeoutPages.push({
                                  id: idListPage,
                                  selected: false,
                                  page: URLactual.pathname,
                                  title: itemPage.title ? itemPage.title : URLactual.pathname,
                                  cTitle: itemPage.title ? itemPage.title : '',
                                  pro: itemPage.pro ? true : false,
                                  url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                  // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                  inlinks: filterInlinksPage.length,
                                  inlinksList: filterInlinksPage,
                                  errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                  name: URLactual.pathname,
                                  cUrl: itemPage.url,
                                  status: itemPage.status,
                                  redirectTo: itemPage.redirectTo
                                  // exist: true,
                                })
                              }
                            } else {
                              var filterP = arrayOtherPages.filter(ele => ele.cUrl === itemPage.url)
                              if(filterP.length) {
                                idListPage -= 1
                              } else {
                                arrayOtherPages.push({
                                  id: idListPage,
                                  selected: false,
                                  page: URLactual.pathname,
                                  title: itemPage.title ? itemPage.title : URLactual.pathname,
                                  cTitle: itemPage.title ? itemPage.title : '',
                                  pro: itemPage.pro ? true : false,
                                  url: domainPrefix === 'non-www' && itemPage.url.includes('www.') ? itemPage.url : domainPrefix === 'www' && !itemPage.url.includes('www.') ? itemPage.url : URLactual.pathname,
                                  // inlinks: itemPage.inlinks && itemPage.inlinks.length ? itemPage.inlinks.length : 0,
                                  inlinks: filterInlinksPage.length,
                                  inlinksList: filterInlinksPage,
                                  errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                                  name: URLactual.pathname,
                                  cUrl: itemPage.url,
                                  status: itemPage.status,
                                  redirectTo: itemPage.redirectTo
                                  // exist: true,
                                })
                              }
                            }
                          }
                        } else {
                          if(filterPro.length >= 1) {
                            filterPage.forEach(elePro => {
                              elePro.pro = true
                              elePro.status = itemPage.status
                              elePro.errors = filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0)
                            })
                          } else if(URLactual.pathname === '/') {
                            filterPage.forEach(elePro => {
                              // elePro.pro = true
                              elePro.status = itemPage.status
                              elePro.errors = filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0)
                            })
                          }
                        }
                      }
                    })

                    var filterHomePage = this.listPagesSidebars.filter(ele => ele.id === 0)
                    if(filterHomePage.length === 0) {
                      this.listPagesSidebars.unshift({
                        id: 0,
                        selected: false,
                        page: 'home',
                        title: 'home',
                        pro: true,
                        url: 'Homepage',
                        inlinks: filterInlinksHome.length,
                        inlinksList: filterInlinksHome,
                        errors: 0,
                        name: 'a',
                        cUrl: siteHomepage,
                        status: 'timeout'
                        // exist: true,
                      })
                    }

                    var filterStatusHome = arrayPages.filter(ele => ele.url === siteHomepage && ele.status !== null)
                    if(filterHomePage.length && filterStatusHome.length) {
                      // console.log(filterStatusHome)
                      if(filterStatusHome.length === 1) {
                        this.listPagesSidebars[0].status = filterStatusHome[0].status
                      } else {
                        var status200 = filterStatusHome.filter(ele => ele.status === 200)
                        var status302 = filterStatusHome.filter(ele => ele.status === 302)
                        var status301 = filterStatusHome.filter(ele => ele.status === 301)
                        var status404 = filterStatusHome.filter(ele => ele.status === 404)
                        if(status200.length) {
                          this.listPagesSidebars[0].status = status200[0].status
                        } else if(status302.length) {
                          this.listPagesSidebars[0].status = status302[0].status
                        } else if(status301.length) {
                          this.listPagesSidebars[0].status = status301[0].status
                        } else if(status404.length) {
                          this.listPagesSidebars[0].status = status404[0].status
                        } else {
                          this.listPagesSidebars[0].status = filterStatusHome[0].status
                        }
                      }
                    }

                    var condicionParaOrdenar = (pageA, pageB) => {
                      return pageB.inlinks - pageA.inlinks;
                    }
                    arrayProPages.sort(condicionParaOrdenar)
                    array200Pages.sort(condicionParaOrdenar)
                    array200XML.sort(condicionParaOrdenar)
                    array302Pages.sort(condicionParaOrdenar)
                    array301Pages.sort(condicionParaOrdenar)
                    array404Pages.sort(condicionParaOrdenar)
                    arrayTimeoutPages.sort(condicionParaOrdenar)
                    arrayOtherPages.sort(condicionParaOrdenar)
                    var incrementalIdPages = 0

                    // array200Pages.push({
                    //   pro: false,
                    //   cUrl: 'https://viventi.com.gt/test2',
                    //   status: 200,
                    //   id: 1,
                    //   selected: false,
                    //   page: 'test',
                    //   title: 'test',
                    //   cTitle: 'test',
                    //   url: '/test2',
                    //   inlinks: 0,
                    //   inlinksList: [],
                    //   errors: filterExternals.length + (filterObjErrors.length >= 1 ? filterObjErrors[0].counter : 0),
                    //   name: 'test',
                    //   redirectTo: null
                    // })

                    arrayProPages.forEach(ele => {
                      incrementalIdPages += 1
                      this.listPagesSidebars.push({
                        id: incrementalIdPages,
                        selected: ele.selected,
                        page: ele.page,
                        title: ele.title,
                        cTitle: ele.cTitle,
                        pro: ele.pro,
                        url: ele.url,
                        inlinks: ele.inlinks,
                        inlinksList: ele.inlinksList,
                        errors: ele.errors,
                        name: ele.name,
                        cUrl: ele.cUrl,
                        status: ele.status,
                        redirectTo: ele.redirectTo
                        // exist: true,
                      })
                    })
                    
                    array200Pages.forEach(ele => {
                      incrementalIdPages += 1
                      this.listPagesSidebars.push({
                        id: incrementalIdPages,
                        selected: ele.selected,
                        page: ele.page,
                        title: ele.title,
                        cTitle: ele.cTitle,
                        pro: ele.pro,
                        url: ele.url,
                        inlinks: ele.inlinks,
                        inlinksList: ele.inlinksList,
                        errors: ele.errors,
                        name: ele.name,
                        cUrl: ele.cUrl,
                        status: ele.status,
                        redirectTo: ele.redirectTo
                        // exist: true,
                      })
                    })

                    // array302Pages.forEach(ele => {
                    //   incrementalIdPages += 1
                    //   this.listPagesSidebars.push({
                    //     id: incrementalIdPages,
                    //     selected: ele.selected,
                    //     page: ele.page,
                    //     title: ele.title,
                    //     cTitle: ele.cTitle,
                    //     pro: ele.pro,
                    //     url: ele.url,
                    //     inlinks: ele.inlinks,
                    //     inlinksList: ele.inlinksList,
                    //     errors: ele.errors,
                    //     name: ele.name,
                    //     cUrl: ele.cUrl,
                    //     status: ele.status,
                    //     redirectTo: ele.redirectTo
                    //     // exist: true,
                    //   })
                    // })

                    // array200XML.forEach(ele => {
                    //   incrementalIdPages += 1
                    //   this.listPagesSidebars.push({
                    //     id: incrementalIdPages,
                    //     selected: ele.selected,
                    //     page: ele.page,
                    //     title: ele.title,
                    //     cTitle: ele.cTitle,
                    //     pro: ele.pro,
                    //     url: ele.url,
                    //     inlinks: ele.inlinks,
                    //     inlinksList: ele.inlinksList,
                    //     errors: ele.errors,
                    //     name: ele.name,
                    //     cUrl: ele.cUrl,
                    //     status: ele.status,
                    //     redirectTo: ele.redirectTo
                    //     // exist: true,
                    //   })
                    // })

                    // array301Pages.forEach(ele => {
                    //   incrementalIdPages += 1
                    //   this.listPagesSidebars.push({
                    //     id: incrementalIdPages,
                    //     selected: ele.selected,
                    //     page: ele.page,
                    //     title: ele.title,
                    //     cTitle: ele.cTitle,
                    //     pro: ele.pro,
                    //     url: ele.url,
                    //     inlinks: ele.inlinks,
                    //     inlinksList: ele.inlinksList,
                    //     errors: ele.errors,
                    //     name: ele.name,
                    //     cUrl: ele.cUrl,
                    //     status: ele.status,
                    //     redirectTo: ele.redirectTo
                    //     // exist: true,
                    //   })
                    // })

                    // array404Pages.forEach(ele => {
                    //   incrementalIdPages += 1
                    //   this.listPagesSidebars.push({
                    //     id: incrementalIdPages,
                    //     selected: ele.selected,
                    //     page: ele.page,
                    //     title: ele.title,
                    //     cTitle: ele.cTitle,
                    //     pro: ele.pro,
                    //     url: ele.url,
                    //     inlinks: ele.inlinks,
                    //     inlinksList: ele.inlinksList,
                    //     errors: ele.errors,
                    //     name: ele.name,
                    //     cUrl: ele.cUrl,
                    //     status: ele.status,
                    //     redirectTo: ele.redirectTo
                    //     // exist: true,
                    //   })
                    // })

                    // arrayTimeoutPages.forEach(ele => {
                    //   incrementalIdPages += 1
                    //   this.listPagesSidebars.push({
                    //     id: incrementalIdPages,
                    //     selected: ele.selected,
                    //     page: ele.page,
                    //     title: ele.title,
                    //     cTitle: ele.cTitle,
                    //     pro: ele.pro,
                    //     url: ele.url,
                    //     inlinks: ele.inlinks,
                    //     inlinksList: ele.inlinksList,
                    //     errors: ele.errors,
                    //     name: ele.name,
                    //     cUrl: ele.cUrl,
                    //     status: ele.status,
                    //     redirectTo: ele.redirectTo
                    //     // exist: true,
                    //   })
                    // })

                    // arrayOtherPages.forEach(ele => {
                    //   incrementalIdPages += 1
                    //   this.listPagesSidebars.push({
                    //     id: incrementalIdPages,
                    //     selected: ele.selected,
                    //     page: ele.page,
                    //     title: ele.title,
                    //     cTitle: ele.cTitle,
                    //     pro: ele.pro,
                    //     url: ele.url,
                    //     inlinks: ele.inlinks,
                    //     inlinksList: ele.inlinksList,
                    //     errors: ele.errors,
                    //     name: ele.name,
                    //     cUrl: ele.cUrl,
                    //     status: ele.status,
                    //     redirectTo: ele.redirectTo
                    //     // exist: true,
                    //   })
                    // })
                  
                } else {
                  // console.log('first')
                  this.listPagesSidebars.forEach(ele => {
                    if(site.data().proPages.includes(ele.cUrl))
                    ele.pro = site.data().proPages.includes(ele.cUrl) ? true : false
                  })

                  this.siteObj[0].ownedBy = site.data().owner && site.data().owner.uid === user.uid ? site.data().owner : {}
                  this.siteObj[0].imgSite = site.data().thumbnail ? site.data().thumbnail : 'https://src.seocloud.ai/site-thumb.png'
                }
              }
            })

            // console.log(this.$route.name,this.$route.name === 'project-sites' || this.$route.name === 'view-errors' || this.$route.name === 'shared-site')
            if(this.$route.name === 'project-sites' || this.$route.name === 'view-errors' || this.$route.name === 'shared-site') {
              // console.log(this.$route.params.id)
              var projectID = this.$route.name === 'project-sites' ? this.$route.params.id : this.$route.name === 'view-errors' || this.$route.name === 'shared-site' ? this.$route.params.project : this.$route.params.id
              db.collection('Projects').doc(projectID).get()
              .then(projects => {
                console.log(projects.exists)
                if(projects.exists) {
                  this.owner = 'me'
                  this.colorProject = projects.data().color ? projects.data().color : ''
                  this.invitationsProject = 0
                  this.invitationsProject = 0
                  this.title = projects.data().name ? projects.data().name : ''
                  this.idsite.label = projects.data().name ? projects.data().name : ''
                  this.idsite.color = projects.data().color ? projects.data().color : ''

                  db.collection('Projects').doc(projects.id).collection('sites').doc(this.$route.params.id).get()
                  .then(projectSite => {
                    if(projectSite.exists) {
                      var atomVarCancel = projectSite.data().cancelSub ? projectSite.data().cancelSub : false
                      var downgrade_date = projectSite.data().downgrade_date ? projectSite.data().downgrade_date : false
                      var downgrade_planed = false
                      var nextDueCancel = projectSite.data().due_end ? moment(projectSite.data().due_end*1000).format('ll') : projectSite.data().due ? moment(projectSite.data().due*1000).add(1, 'month').format('ll') : moment(Date.now()).add(1, 'month').format('ll')
                      var subscriptionId = projectSite.data().subscriptionId ? projectSite.data().subscriptionId : ''
                      if(downgrade_date) {
                        var dueCancel = moment(downgrade_date*1000)
                        var dateActual = moment()

                        // Diferencia en días
                        var differenceInMinutes = dueCancel.diff(dateActual, 'minutes');
                        // console.log(differenceInMinutes, dueCancel, dateActual);
                        if(differenceInMinutes < 0) {
                          downgrade_planed = false
                          db.collection('Projects').doc(projectID).collection('sites').doc(site.id).update({
                            downgrade_date: ''
                          })
                        } else {
                          downgrade_planed = true
                        }
                      }
                      this.cancelSub = atomVarCancel ? true : downgrade_planed === true && nextDueCancel && subscriptionId ? true : false

                      console.log(projectSite.exists);
                      var plan = projectSite.data().plan ? (projectSite.data().plan === 'basic' || projectSite.data().plan === 'Basic' || projectSite.data().plan === 'pro' || projectSite.data().plan === 'Pro' || projectSite.data().plan === 'custom' || projectSite.data().plan === 'Custom' || projectSite.data().plan === 'test' || projectSite.data().plan === 'Test' || projectSite.data().plan === 'free' || projectSite.data().plan === 'Free') : 'Free'
                      if(plan && (projectSite.data().planType || !projectSite.data().planType)) {
                        // console.log(projectSite.data().planType, plan)
                        this.planLicensed = (projectSite.data().planType ? projectSite.data().planType : 'free')
                      }

                      if(projects.data().name && this.siteObj.length) {
                        this.siteObj[0].name = projectSite.data().name ? projectSite.data().name : ''
                      }
                    }
                  })

                  // array of members
                  this.arrayInvitations = []
                } else {
                  // console.log(projectID)
                  this.idsite.idsite = projectID ? projectID : ''
                  this.idsite.label = 'My sites'
                  this.idsite.color = '#181E38'
                  this.idsite.permission = 'owner'
                }
              })
            }
          }
        })
      }
    },
    takeScreenshotNow(website) {
      let data = JSON.stringify({
        "website": website
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://sslcheckerapi-h4klmyt43a-uc.a.run.app/screenshots/register',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      this.axios(config)
      .then((response) => {
        console.log('register screenshots: ', response)
      })
      .catch((error) => {
        console.error('Error register screenshot: ', error)
      });
    },
    openRegister() {
      // console.log('open register')
      const user = auth.currentUser
      user ? this.showOpenAddSiteProject = true : this.showOpenRegisterSidebar = true
    },
    getSection() {
      // console.log('get section')
      var url = new URL(window.location)
      var urlParams = new URLSearchParams(window.location.search)
      var myParamSection = urlParams.get('section')
      var myParamContent = urlParams.get('content')
      this.urlActual = url.protocol + '//' + url.host + '/share?site=' + this.idsite.domain
      url.search.includes('?site=') ? this.urlActualQuery = url.protocol + '//' + url.host + '/share' + url.search : this.urlActualQuery = url.protocol + '//' + url.host + '/share?site=' + this.idsite.domain + '&' + url.search.substring(1)
      this.urlSection = myParamSection
      this.urlContent = myParamContent
      // console.log(url.pathname)
      // console.log(myParamSection,myParamContent,this.urlActual)http://localhost:8080/share?site=seotest1.dev2.facadeinteractive.comsection=errors&content=dL
    },
    onCopy() {
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: 'Url copied',
      //     icon: 'BellIcon',
      //   },
      // })
      this.copiedEverything = true
      setTimeout(() => {
        this.copiedEverything = false
      }, 2000)
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy url',
          icon: 'BellIcon',
        },
      })
    },
    copyEverything() {
      this.$copyText(this.urlActual)
      // comment http problems
      // navigator.clipboard.writeText(this.urlActual)
      // const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy'); console.log('copied')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};
      // if(window.isSecureContext && navigator.clipboard) {
        // navigator.clipboard.writeText(this.urlActual);
        // execCommand('copy', true, 'tesxt')
      // } else {
        // unsecuredCopyToClipboard(this.urlActual);
      // }

      // var textArea = document.createElement("textarea");
      // textArea.value = this.urlActual;
      
      // // Avoid scrolling to bottom
      // textArea.style.top = "0";
      // textArea.style.left = "0";
      // textArea.style.position = "fixed";

      // document.body.appendChild(textArea);
      // // textArea.focus();
      // // textArea.select();
      // document.execCommand('copy');

      this.copiedEverything = true
      setTimeout(() => {
        this.copiedEverything = false
      }, 2000)
    },
    copyLink() {
      this.$copyText(this.urlActualQuery)
      // comment http problems
      // navigator.clipboard.writeText(this.urlActualQuery)
      // const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy'); console.log('copied')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};
      // if(window.isSecureContext && navigator.clipboard) {
        // navigator.clipboard.writeText(this.urlActualQuery);
        // execCommand('copy', true, 'asdf')
      // } else {
        // unsecuredCopyToClipboard(this.urlActualQuery);
      // }

      // var textArea = document.createElement("textarea");
      // textArea.value = this.urlActualQuery;
      
      // // Avoid scrolling to bottom
      // textArea.style.top = "0";
      // textArea.style.left = "0";
      // textArea.style.position = "fixed";

      // document.body.appendChild(textArea);
      // textArea.focus();
      // textArea.select();
      // document.execCommand('copy');
      

      this.copiedLink = true
      setTimeout(() => {
        this.copiedLink = false
      }, 2000)
    }
  },
  mounted() {
    this.isNotAlreadyOwner = true
    this.siteObj = []
    this.planLicensed = ''
    this.timerChangeInSite = setInterval(() => {
      var changeInSite = localStorage.getItem('changeInSite')
      if(changeInSite) {
        // console.log('changeT');
        this.chargeSite()
        localStorage.removeItem('changeInSite')
      }

      var changeNewThumb = localStorage.getItem('changeNewThumb')
      if(changeNewThumb) {
        // console.log('changeT');
        this.chargeSite()
        localStorage.removeItem('changeNewThumb')
      }

      var getClaimLicense = localStorage.getItem('sidebarsTriggersHead')
      if(getClaimLicense) {
        var user = auth.currentUser
        localStorage.removeItem('sidebarsTriggersHead')
        if(user) {
          this.showOpenArchiveSite = false
          this.showOpenAddSiteProject = false
          this.showOpenRegisterSidebar = false
          this.showOpenSidebarProPages = false
          // this.showOpen = false
        } else {
          // 
        }
      }
    }, 1000)
  },
  destroyed() {
    this.isNotAlreadyOwner = true
    this.siteObj = []
    this.planLicensed = ''
    
    clearInterval(this.timerChangeInSite)
  }
}
</script>

<style>
/* .no-gutters #dropdown-title-id a {
  z-index: 99999;
  position: fixed;
} */
.dropdown-title a {
  padding-top: 0px;
  padding-left: 0px;
}
.dropdown-menu-right {
  width: 115px !important;
  min-width: 101px !important;
  margin-top: 0px !important;
  border-radius: 10px;
  border: 1px solid #bbbbb9 !important;
}
.dropdown-menu-right .b-dropdown-form {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-top: 4px !important;
}
.item-dropdown-user {
  padding: 0px auto;
}
.item-dropdown-user:hover {
  background: none !important;
  background-color: none !important;
  color: #000 !important;
}
.user-dropdown-text::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
  position: absolute;
  margin-top: 10px;
}
.dropdown-title::marker {
  display: none !important;
  color: transparent !important;
}
.dropdown-title .dropdown-menu {
  position: absolute;
  top: -2px;
  width: 125px !important;
  padding: 1px;
}
.dropdown-title .dropdown-menu li a {
  /* padding: 10px; */
  padding: 6px 10px 3px 10px;
  text-align: center !important;
}
.dropdown-title .show {
  background: transparent !important;
  border: 1px solid transparent !important;
  z-index: 11; display: inline-table;
}
.dropdown-second-line-form {
  padding-top: 1px !important;
}
.dropdown-second-line-form form {
  padding-bottom: 4px !important;
}
.content.app-content {
  /* padding: calc(1rem + 4rem + 1.3rem) 2rem 0 !important; */
  padding: calc(2rem) 2rem 0 !important;
}
.text-switch {
  color: #a7aab9;
}
.custom-switch .custom-control-label::before {
  background-color: #232C51;
}
.btn-nav-page {
  /* top: 120px; */
  /* left: 1449px; */
  width: 132px;
  height: 46px;
  background: transparent linear-gradient(114deg, #7838ff 0%, #7838FF 100%) 0% 0% no-repeat padding-box;
  border-color: transparent !important;
  border-radius: 15px;
  position: absolute !important;
  right: 0px;
  top: -8px;
  padding: 0px;
  border: 0px;
  /* opacity: 1; */
}
.btn-nav-page:hover {
  /* top: 120px; */
  /* left: 1449px; */
  width: 132px;
  height: 46px;
  background: #7838ff !important;
  /* border-color: #7838ff !important; */
  border-radius: 15px;
  position: absolute !important;
  right: 0px;
  top: -8px;
  padding: 0px;
  border: 0px;
  /* opacity: 1; */
}
.btn-nav-page:active:focus {
  /* top: 120px; */
  /* left: 1449px; */
  width: 132px;
  height: 46px;
  background: #7838ff !important;
  /* border-color: #7838ff !important; */
  border-radius: 15px;
  position: absolute !important;
  right: 0px;
  top: -8px;
  padding: 0px;
  border: 0px;
  /* opacity: 1; */
}
#box-nav {
  /* position: relative; */
  /* width: 200px; */
  /* height: 50px; */
  /* background-color: blue; */
  /* border-radius: 9999px 0 0 9999px; */
  /* margin: 30px; */
  /* text-align: center; */
  color: #fff;
  /* padding-top: 10px; */
}

/* #top, */
#bottom-nav {
  position: absolute;
  height: 30px;
  width: 30px;
  left: 0;
  overflow: hidden;
}

/* #top {
  top: -30px;
} */

#bottom-nav {
  bottom: -30px;
}

/* #top::before, */
#bottom-nav::before {
  content: '';
  position: absolute;
  left: 0;
  height: 200%;
  width: 200%;
  border-radius: 100%;
  box-shadow: 10px 10px 5px 100px #181E38;
  z-index: -1;
}
.style-chooser-title .vs__search::placeholder,
.style-chooser-title .vs__dropdown-toggle,
.style-chooser-title .vs__dropdown-menu {
  /* background: #fff; */
  border: none;
  padding: 0px !important;
  color: #fff !important;
  /* text-transform: lowercase; */
  /* font-variant: small-caps; */
}

.style-chooser-title .vs__clear,
.style-chooser-title .vs__open-indicator {
  fill: #fff !important;
  padding: 0px !important;
}
.style-chooser-title .dropdown-toggle {
    background: transparent !important;
    border: 0px !important;
    padding: 0px !important;
    color: #fff !important;
}
.style-chooser-title .dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white' stroke='' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}
.style-chooser-title .dropdown-item {
    padding: 0px 5px;
}
.btn-back-title-all:active:visited {
  color: #ffffff;
  display: block;
  text-align: left;
  width: 40px;
  position: absolute;
  top: -20px;  
}
.btn-back-title-err:active:visited:focus {
  color: #ffffff;
  display: block;
  text-align: left;
  width: 20px;
  /* position: absolute !important; */
  top: 10px !important;
  left: -5px !important;
  box-shadow: none !important;
  position: absolute !important;
  background: transparent;
}
.btn-back-title-edit:active:visited {
  color: #ffffff;
  display: block;
  text-align: left;
  width: 40px;
  position: absolute;
  top: -20px;
}
.btn-back-title-project:active:visited {
  color: #ffffff;
  display: block;
  text-align: left;
  width: 40px;
  position: absolute;
  top: -60px;
}
.ripple-container {
  display: none !important;
}
.url_link:hover {
  color: #A7AAB9 !important;
}
.eyeBtn {
  display: none !important;
}
.url_links:hover .eyeBtn {
  display: inline-flex !important;
  position: absolute;
  /* top: 2px; */
  margin-top: 5px;
  margin-left: 5px !important;
}
.btn-dropdown-menu-header:hover {
  color: #000 !important;
}
@media (min-width: 1200px) {
  .title-contain .navbar-container {
    margin-top: 0px !important;
  }
  .title-contain .navbar-container .card-title-container {
    margin-top: 14.5px !important;
  }
  .title-contain .navbar-container .card-title-container-owner {
    margin-top: 13px !important;
  }
  .btn-back-title-err {
    top: 5px !important;
  }
  .card-title-container .no-gutters {
    padding-top: 10px;
  }
  .card-title-container-owner .no-gutters {
    padding-top: 10px;
  }
  .title-page-header {
    padding-top: 34px !important;
  }
  .project-name-header {
    top: 65px !important;
  }
  .btns-right-xl-devices div {
    margin-top: 29px !important;
  }
  .btns-right-xl-devices-shared div {
    margin-top: 5px !important;
  }
  .title-contain .title-standard-page {
    height: auto !important;
  }
}
@media (max-width: 1199px) {
  .white-background {
    margin-left: 60px !important;
  }
  .project-name-header {
    left: 70px !important;
    top: 65px !important;
  }
  .title-contain .navbar-container {
    /* margin-top: 10px !important; */
    margin-left: 50px !important;
    /* margin-left: 32px !important; */
  }
  .btn-back-title-all {
    top: 0px !important;
    left: -32px !important;
  }
  .btn-back-title-err {
    top: 0px !important;
    left: -32px !important;
  }
  .btn-back-title-edit {
    top: 10px !important;
    left: -32px !important;
  }
  .btn-back-title-project {
    top: -25px !important;
    left: -32px !important;
  }
  .title-contain .title-standard-page {
    height: auto !important;
  }
}
@media (min-width: 769px) {
  .btns-right-sm-devices {
    display: none !important;
  }
  .title-contain .title-standard-page {
    height: auto !important;
  }
}
@media (max-width: 1199px) and (min-width: 769px) {
  .title-contain .navbar-container {
    margin-top: 28px !important;
    margin-bottom: 6px !important;
    height: 100px !important;
  }
  .project-name-header {
    padding-top: 15px !important;
  }
  
  .card-title-container {
    margin-top: 15px !important;
    /* padding-top: 18px; */
  }
  .card-title-container-owner {
    margin-top: 15px !important;
    /* padding-top: 18px; */
  }
  .title-contain .title-standard-page {
    height: auto !important;
  }
  .contain-img-head-shared {
    margin-top: 20px !important;
  }
  .btns-right-xl-devices-shared {
    margin-top: 19px !important;
  }
}
@media (max-width: 768px) {
  .owner-user-share-results {
    margin-left: 65px !important;
    /* margin-left: 18px !important; */
  }
  .sm-user-shared-results {
    /* margin-left: 58px !important; */
    margin-left: 18px !important;
    margin-top: 0px !important;
  }
  .dropdown-title .show {
    right: 130px !important;
  }
  .dropdown-title .show .dropdown-menu {
    /* position: initial !important; */
    /* right: 150px !important; */
    right: 120px !important;
    position: relative !important;
    margin-top: 10px !important;
  }
  .title-contain .navbar-container {
    /* margin-top: 10px !important; */
    /* margin-left: 50px !important; */
    margin-left: 32px !important;
    margin-bottom: 0px !important;
  }
  .btn-back-title-err {
    top: 0px !important;
    left: -20px !important;
  }
  .white-background {
    margin-left: 0px !important;
    border-radius: 30px 30px 0 0;
  }
  .project-name-header {
    /* left: 72px !important;
    top: 75px !important; */
    position: initial !important;
    padding-top: 30px;
    margin-left: 55px;
  }
  .project-name-header a h4 {
    margin-bottom: 0px !important;
    padding-bottom: 8px;
  }
  .title-contain .navbar-container {
    /* margin-top: 28px !important; */
    margin-top: 0px !important;
    /* margin-bottom: 6px !important; */
    margin-bottom: 0px !important;
  }
  .title-contain .navbar-container-shared {
    margin-left: 0px !important;
    padding-left: 0px !important;
    height: auto !important;
    min-height: auto !important;
  }
  .title-contain .nav-cont-header {
    margin-top: 0px !important;
    /* margin-bottom: 16px !important; */
    height: 130px !important;
  }
  .nav-link-custom .url_links {
    margin-left: 0px !important;
    /* padding-top: 17px; */
    padding-top: 0px;
  }
  .nav-link-custom .url_links-shared {
    margin-left: 0px !important;
    margin-top: 0px;
    padding-top: 0px;
  }
  .sm-dv {
    margin-left: 155px !important;
    /* top: 61px !important; */
    top: calc(100% - 44px) !important;
  }
  .sm-dv-licensed {
    margin-left: 155px !important;
    /* top: 61px !important; */
    /* top: calc(100% - 53px) !important; */
    top: calc(100% - 36px) !important;
  }
  .sm-dv-no-licensed {
    margin-left: 155px !important;
    top: calc(100% - 36px) !important;
  }
  .nav-cont-header .sm-dv {
    top: 90px !important;
  }
  .nav-cont-header .sm-dv-owner {
    top: 84px !important;
    margin-left: 165px !important;
  }
  .card-title-container-owner .no-gutters {
    margin-top: 10px !important;
  }
  .sm-dv-shared {
    margin-left: 150px !important;
    /* top: 57px !important; */
    top: 52px !important;
  }
  .btns-right-xl-devices {
    display: none !important;
  }
  .btns-right-xl-devices-shared {
    display: none !important;
  }
  .btns-right-sm-devices .dropdown-menu.show {
    display: contents !important;
  }
  .img-title-header {
    width: 150px !important;
    height: 100px !important;
    padding: 8px 0px !important;
    display: none !important;
  }
  .title-contain .title-standard-page {
    /* height: auto !important; */
    height: 110px !important;
  }
  .url_link {
    max-width: 280px !important;
  }
  .card-title-container .no-gutters {
    max-height: 190px !important;
  }
  .card-title-container-owner .no-gutters {
    max-height: 190px !important;
  }
  .card-title-container .no-gutters .dropdown-title {
    max-height: 190px !important;
  }
  .card-title-container-owner .no-gutters .dropdown-title {
    max-height: 190px !important;
  }
  .name_site_header {
    max-width: 280px !important;
    white-space: break-spaces !important;
    max-height: 90.5px !important;
  }
  .title-contain .navbar-container {
    max-height: 190px !important;
    /* height: auto !important; */
    /* min-height: 130px !important; */
  }
}
@media (max-width: 768px) {
  .share-img {
    /* top: -6px !important; */
    left: -4px !important;
  }
  .share-text {
    left: 0px !important;
  }
  .contain-img-head-shared {
    margin-top: 0px !important;
  }
  .copilot-title-owner {
    z-index: 10;
  }
}
@media (max-width: 560px) {
  .dropdown-title a {
    width: 195px;
  }
  .owner-user-share-results {
    margin-left: 69px !important;
    /* margin-left: 18px !important; */
  }
  .share-img {
    top: -8px !important;
    left: -4px !important;
  }
  .share-text {
    left: 0px !important;
    top: -5px !important;
  }
  .nav-cont-header .sm-dv-owner {
    margin-left: 110px !important;
  }
  .btns-right-sm-devices {
    /* width: 100px;
    font-size: 13px; */
    width: 175px !important;
  }
  /* .btns-right-sm-devices button { */
    /* width: 105px; */
    /* width: 175px !important; */
    /* width: 135px !important; */
  /* } */
  .btns-right-sm-devices button span {
    /* width: 105px; */
    /* width: 105px !important; */
    font-size: 13px !important;
  }
  .copilot-title-owner {
    width: 147px !important;
    font-size: 13px;
    padding-left: 0px !important;
    z-index: 10;
  }
  .copilot-title-owner span {
    text-align: left;
    padding-left: 2px !important;
    margin-left: 0px !important;
  }
  .copilot-title-owner img {
    width: 12px;
    margin-left: 3px !important;
    margin-right: 3px !important;
  }
  .copilot-title-owner svg {
    margin-left: 2px !important;
  }
  .sm-user-share-results {
    width: 123px !important;
    /* margin-left: 125px !important; */
  }
  .sm-btn-claim {
    width: 95px !important;
    font-size: 13px !important;
  }
  .sm-dv-no-licensed {
    margin-left: 125px !important;
    top: calc(100% - 36px) !important;
  }
  .add-sm-btn-shared {
    width: 105px !important;
  }
  .sm-user-shared-results {
    /* margin-left: 58px !important; */
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
  .sm-dv-licensed-no-owner {
    margin-left: 125px !important;
  }
}
.dropdown-menu-right-open-tab {
  height: 78px !important;
}
.nav-open-tab ul {
  height: auto !important;
}
#dropdown-title-id ul {
  height: auto !important;
}
</style>